import { z } from "zod";
import {
  FirebaseConfigSchema,
  MessageErrorSchema,
  SignupInsitutionalInvestorPayloadSchema,
  SignupInvestorPayloadSchema,
  SignupMarketProfPayloadSchema,
  SignupMunicipalityPayloadSchema,
  UserMetadataSchema,
} from "../schemas/index.js";
import { StatusCodes } from "../utils/http-status-codes.js";
import { apiContract } from "./contract.js";

export const AuthRouter = apiContract.router(
  {
    // ---- Get current user metadata ----
    getUserMetadata: {
      method: "GET",
      path: "metadata",
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: UserMetadataSchema,
        }),
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Fetch current user data",
    },
  },
  // ---- Options ----
  {
    pathPrefix: "/api/auth/",
    strictStatusCodes: true,
  },
);

export const AuthPublicRouter = apiContract.router(
  {
    // ---- Get firebase config ----
    getFirebaseConfig: {
      method: "GET",
      path: "firebase-config",
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: FirebaseConfigSchema,
        }),
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Get firebase config",
    },

    // ---- Sign up as municipality ----
    signUpMuni: {
      method: "POST",
      path: "municipality-signup",
      body: SignupMunicipalityPayloadSchema,
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: z
            .object({
              userId: z.number(),
            })
            .optional(),
        }),
        [StatusCodes.Unauthorized]: MessageErrorSchema,
        [StatusCodes.Conflict]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Sign up Municipality",
    },

    // ---- Sign up as individual investor ----
    signUpInvestor: {
      method: "POST",
      path: "investor-signup",
      body: SignupInvestorPayloadSchema,
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: z
            .object({
              userId: z.number(),
            })
            .optional(),
        }),
        [StatusCodes.Unauthorized]: MessageErrorSchema,
        [StatusCodes.Conflict]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Sign up as Investor",
    },

    // ---- Sign up as institutional investor ----
    signUpInstitutionalInvestor: {
      method: "POST",
      path: "inst-investor-signup",
      body: SignupInsitutionalInvestorPayloadSchema,
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: z
            .object({
              userId: z.number(),
            })
            .optional(),
        }),
        [StatusCodes.Unauthorized]: MessageErrorSchema,
        [StatusCodes.Conflict]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Sign up as Institutional Investor",
    },

    // ---- Sign up as market professional ----
    signUpMarketProfessional: {
      method: "POST",
      path: "market-prof-signup",
      body: SignupMarketProfPayloadSchema,
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: z
            .object({
              userId: z.number(),
            })
            .optional(),
        }),
        [StatusCodes.Unauthorized]: MessageErrorSchema,
        [StatusCodes.Conflict]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Sign up as Market Professional",
    },
  },
  // ---- Options ----
  {
    pathPrefix: "/api/auth/",
    strictStatusCodes: true,
  },
);
