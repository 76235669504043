import { z } from "zod";
import {
  AuctionListItemSchema,
  AuctionProfessionalSchema,
  AuctionProfileSchema,
  AuctionRatingsProvidersSchema,
  AuctionDetailsSchema,
  AuctionOrderPrioritySchema,
  UpdateAuctionDatesAndCommunityBodySchema,
  AuctionDocumentsRequestSchema,
  AuctionDocumentTypesSchema,
  AuctionMaturityStructureFormSchema,
  AllAuctionsListItemSchema,
  AuctionListFilterSchema,
  SubmitFeedbackRequestSchema,
  AuctionInventorySchema,
  AuctionInvestmentsFormSchema,
  AuctionBidResponseSchema,
  AuctionSubscriptionResponseSchema,
  AuctionInvestorDetailsSchema,
} from "../schemas/auction.schema.js";
import { MessageErrorSchema } from "../schemas/http-response.schema.js";
import { StatusCodes } from "../utils/http-status-codes.js";
import { apiContract } from "./contract.js";

export const AuctionRouter = apiContract.router(
  {
    // ---- Get auction details ----
    // this is only for the municipality admin
    getOwnAuctionDetails: {
      method: "GET",
      path: ":auctionId/own",
      pathParams: z.object({
        auctionId: z.string(),
      }),
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: AuctionDetailsSchema,
        }),
        [StatusCodes.BadRequest]: MessageErrorSchema,
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.Forbidden]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Get auction details for create/update",
    },

    // ---- Get auction details ----
    // this is for investors
    getAuctionPublicDetails: {
      method: "GET",
      path: ":auctionId/public",
      pathParams: z.object({
        auctionId: z.string(),
      }),
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: AuctionInvestorDetailsSchema,
        }),
        [StatusCodes.BadRequest]: MessageErrorSchema,
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.Forbidden]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Get auction details for investors",
    },

    // ---- create auction draft ----
    createDraft: {
      method: "POST",
      path: "",
      body: AuctionProfileSchema,
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: z.object({
            id: z.string(),
          }),
        }),
        [StatusCodes.BadRequest]: MessageErrorSchema,
        [StatusCodes.Forbidden]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Create auction draft",
    },

    // ---- List auctions in the current municipality ----
    listOwnAuctions: {
      method: "GET",
      path: "own",
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: z.array(AuctionListItemSchema),
        }),
        [StatusCodes.BadRequest]: MessageErrorSchema,
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.Forbidden]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "List of auctions in the current municipality",
    },

    // ---- Minimal list of all auctions across municipalities ----
    listAllAuctions: {
      method: "GET",
      path: "all",
      query: AuctionListFilterSchema,
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: z.array(AllAuctionsListItemSchema),
        }),
        [StatusCodes.Forbidden]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "List all auctions across municipalities",
    },

    listProfessionals: {
      method: "GET",
      path: "professionals",
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: z.array(AuctionProfessionalSchema),
        }),
        [StatusCodes.BadRequest]: MessageErrorSchema,
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.Forbidden]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "List of all market professionals",
    },

    listProfessionalsByAuction: {
      method: "GET",
      path: ":auctionId/professionals",
      pathParams: z.object({
        auctionId: z.string(),
      }),
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: z.array(AuctionProfessionalSchema),
        }),
        [StatusCodes.BadRequest]: MessageErrorSchema,
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.Forbidden]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "List of market professionals by auction",
    },

    inviteProfessional: {
      method: "POST",
      path: "invite-professional",
      body: z.object({
        email: z.string().email(),
      }),
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: z.object({
            id: z.string(),
          }),
        }),
        [StatusCodes.BadRequest]: MessageErrorSchema,
        [StatusCodes.Forbidden]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Invite professional",
    },

    // ---- Update auction profile ----
    updateProfile: {
      method: "PUT",
      path: ":auctionId/profile",
      pathParams: z.object({
        auctionId: z.string(),
      }),
      body: AuctionProfileSchema,
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: z.object({
            id: z.string(),
          }),
        }),
        [StatusCodes.BadRequest]: MessageErrorSchema,
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.Forbidden]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Update auction profile",
    },

    // ---- Update auction ratings and providers ----
    updateRatingsAndProviders: {
      method: "PUT",
      path: ":auctionId/ratings-providers",
      pathParams: z.object({
        auctionId: z.string(),
      }),
      body: AuctionRatingsProvidersSchema,
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: z.object({
            id: z.string(),
          }),
        }),
        [StatusCodes.BadRequest]: MessageErrorSchema,
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.Forbidden]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Update auction ratings and providers",
    },

    // ---- Update auction priotity ----
    updatePriority: {
      method: "PUT",
      path: ":auctionId/priority",
      pathParams: z.object({
        auctionId: z.string(),
      }),
      body: AuctionOrderPrioritySchema,
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: z.object({
            id: z.string(),
          }),
        }),
        [StatusCodes.BadRequest]: MessageErrorSchema,
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.Forbidden]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Update auction order and priority",
    },

    // ---- Update auction priority ----
    updateDatesAndCommImpact: {
      method: "PUT",
      path: ":auctionId/dates-community-impact",
      pathParams: z.object({
        auctionId: z.string(),
      }),
      body: UpdateAuctionDatesAndCommunityBodySchema,
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: z.object({
            id: z.string(),
          }),
        }),
        [StatusCodes.BadRequest]: MessageErrorSchema,
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.Forbidden]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Update key dates and community impact themes",
    },

    // ---- Upload documents ----
    uploadDocuments: {
      method: "POST",
      path: ":auctionId/documents",
      pathParams: z.object({
        auctionId: z.string(),
      }),
      contentType: "multipart/form-data",
      body: AuctionDocumentsRequestSchema,
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: z.object({
            id: z.string(),
          }),
        }),
        [StatusCodes.BadRequest]: MessageErrorSchema,
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.Forbidden]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Upload documents",
    },

    // ---- Delete document ----
    deleteDocument: {
      method: "DELETE",
      path: ":auctionId/documents/:documentType",
      pathParams: z.object({
        auctionId: z.string(),
        documentType: AuctionDocumentTypesSchema,
      }),
      body: z.unknown(),
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: z.object({
            id: z.string(),
          }),
        }),
        [StatusCodes.BadRequest]: MessageErrorSchema,
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.Forbidden]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Delete document",
    },

    // ---- Update maturity structure ----
    updateMaturityStructure: {
      method: "PUT",
      path: ":auctionId/maturity-structure",
      pathParams: z.object({
        auctionId: z.string(),
      }),
      body: AuctionMaturityStructureFormSchema,
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: z.object({
            id: z.string(),
          }),
        }),
        [StatusCodes.BadRequest]: MessageErrorSchema,
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.Forbidden]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Update auction maturity structure",
    },

    // ---- Update maturity structure ----
    requestApproval: {
      method: "POST",
      path: ":auctionId/request-approval",
      pathParams: z.object({
        auctionId: z.string(),
      }),
      body: z.unknown(),
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: z.object({
            id: z.string(),
          }),
        }),
        [StatusCodes.BadRequest]: MessageErrorSchema,
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.Forbidden]: MessageErrorSchema,
        [StatusCodes.PreconditionFailed]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Request approval",
    },

    // ---- Submit Feedback ----
    submitFeedback: {
      method: "POST",
      path: ":auctionUuid/feedback",
      pathParams: z.object({
        auctionUuid: z.string(),
      }),
      body: SubmitFeedbackRequestSchema,
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: z.object({
            id: z.string(),
          }),
        }),
        [StatusCodes.BadRequest]: MessageErrorSchema,
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.Forbidden]: MessageErrorSchema,
        [StatusCodes.PreconditionFailed]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Submit feedback",
    },

    goLive: {
      method: "POST",
      path: ":auctionUuid/go-live",
      pathParams: z.object({
        auctionUuid: z.string(),
      }),
      body: z.unknown(),
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: z.object({
            id: z.string(),
          }),
        }),
        [StatusCodes.BadRequest]: MessageErrorSchema,
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.Forbidden]: MessageErrorSchema,
        [StatusCodes.PreconditionFailed]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Auction go live",
    },

    // ---- Get auction inventory ----
    inventory: {
      method: "GET",
      path: ":auctionId/inventory",
      pathParams: z.object({
        auctionId: z.string(),
      }),
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: AuctionInventorySchema,
        }),
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.Forbidden]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Get auction inventory",
    },

    // ---- Get all auction bids by the current user ----
    myAuctionBids: {
      method: "GET",
      path: "my-bids",
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: z.array(AuctionBidResponseSchema),
        }),
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.Forbidden]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Get all auction bids by the current user",
    },

    // ---- Submit bid ----
    submitBid: {
      method: "POST",
      path: ":auctionId/bid",
      pathParams: z.object({
        auctionId: z.string(),
      }),
      body: AuctionInvestmentsFormSchema,
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
        }),
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.BadRequest]: MessageErrorSchema,
        [StatusCodes.Forbidden]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Submit bid",
    },

    subscribeStatusChange: {
      method: "POST",
      path: "subscription/status/:id",
      body: z.object({
        isSubscribedToAuctionStatusChange: z.boolean(),
      }),
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
        }),
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Subscribe/Unsubscribe notification for auction status change",
    },

    subscribeInvestorPresentation: {
      method: "POST",
      path: "subscription/investor-presentation/:id",
      body: z.object({
        isSubscribedToInvestorPresentation: z.boolean(),
      }),
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
        }),
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Subscribe/Unsubscribe notification for investor presentation",
    },

    myAuctionSubscriptions: {
      method: "GET",
      path: "auction-subscriptions",
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: z.array(AuctionSubscriptionResponseSchema),
        }),
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.Forbidden]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Get all auction subscriptions for the current user",
    },
  },

  // ---- Options ----
  {
    pathPrefix: "/api/auction/",
    strictStatusCodes: true,
  },
);
