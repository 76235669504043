import { createRoot } from "react-dom/client";
import { App } from "./src/App.jsx";

import "@css/reset.css";
import "@mantine/core/styles.css";
import "@mantine/notifications/styles.css";
import "@css/app.css";

declare global {
  interface Window {
    // for segment analytics
    analytics: any;
  }
}

const container = document.getElementById("root");
if (!container) {
  throw new Error("Root element not found");
}
createRoot(container).render(<App />);
