import { Box, Button, Flex, Modal } from "@mantine/core";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { BodyText } from "../../atoms/Typography/BodyText.jsx";
import { Subtitle } from "../../atoms/Typography/Subtitle.js";

export interface ConfirmationDialogProps {
  title?: string;
  children: React.ReactNode;
  buttonCancelColor?: string;
  buttonCancelText?: string;
  buttonOkColor?: string;
  buttonOkText?: string;
}

export interface ConfirmationDialogRef {
  getConfirmation: () => Promise<boolean>;
}

export const ConfirmationDialog = forwardRef<
  ConfirmationDialogRef,
  ConfirmationDialogProps
>((props, ref) => {
  const {
    title = "Are you sure",
    children,
    buttonCancelColor = "dark.3",
    buttonCancelText = "Cancel",
    buttonOkColor = "brand",
    buttonOkText = "Ok",
  } = props;

  const onConfirm = useRef(() => {});
  const onCancel = useRef(() => {});

  const [isOpen, setOpen] = useState(false);
  const confirmationValue = useRef(false);

  useImperativeHandle(ref, () => ({
    getConfirmation: () => {
      // returns a promise that resolves to the confirmation value
      return new Promise((resolve) => {
        confirmationValue.current = false;
        setOpen(true);
        onConfirm.current = () => {
          confirmationValue.current = true;
          setOpen(false);
          resolve(confirmationValue.current);
        };
        onCancel.current = () => {
          setOpen(false);
          resolve(confirmationValue.current);
        };
      });
    },
  }));

  return (
    <>
      <Modal
        centered
        opened={isOpen}
        onClose={() => setOpen(false)}
        title={
          <Subtitle c="brand.8" fz="md" level={2}>
            {title}
          </Subtitle>
        }
      >
        <Flex direction="column" gap="lg">
          <Box flex={1}>
            {typeof children === "string" ? (
              <BodyText level={3}>{children}</BodyText>
            ) : (
              children
            )}
          </Box>
          <Flex gap="sm" justify="flex-end">
            <Button
              color={buttonCancelColor}
              variant="outline"
              onClick={onCancel.current}
            >
              {buttonCancelText}
            </Button>
            <Button color={buttonOkColor} onClick={onConfirm.current}>
              {buttonOkText}
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
});
