import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { LoaderFullscreen } from "@munivestor/ui/components";
import { AppTheme } from "@munivestor/ui/constants";
import { useToast } from "@munivestor/ui/hooks";
import { lazyLoad } from "@munivestor/ui/utils";
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { Provider as JotaiProvider } from "jotai/react";
import { useHydrateAtoms } from "jotai/react/utils";
import { queryClientAtom } from "jotai-tanstack-query";
import { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { z } from "zod";
import { ProtectedRoute } from "@atoms/Routing/ProtectedRoute.jsx";
import { RouteMap } from "@constants/RouteMap.js";

// Set up lazy loaded route components for code-splitting
const Signin = lazyLoad(() => import("@features/Auth/Signin.jsx"));
const SignUp = lazyLoad(() => import("@features/Auth/Signup.jsx"));
const TroubleSignin = lazyLoad(
  () => import("@features/Auth/TroubleSigninPage.jsx"),
);
const Dashboard = lazyLoad(
  () => import("@features/ManageMunicipalities/MunicipalityList.jsx"),
);
const MunicipalityDetails = lazyLoad(
  () => import("@features/ManageMunicipalities/MunicipalityDetails.jsx"),
);
const IndividualInvestors = lazyLoad(
  () =>
    import("@features/ManageIndividualInvestors/IndividualInvestorList.jsx"),
);
const IndividualInvestorsDetails = lazyLoad(
  () =>
    import("@features/ManageIndividualInvestors/IndividualInvestorDetails.jsx"),
);
const InstitutionalInvestors = lazyLoad(
  () =>
    import(
      "@features/ManageInstitutionalInvestors/InstitutionalInvestorList.jsx"
    ),
);
const InstitutionalInvestorDetails = lazyLoad(
  () =>
    import(
      "@features/ManageInstitutionalInvestors/InstitutionalInvestorDetails.jsx"
    ),
);
const MarketProfessionals = lazyLoad(
  () =>
    import("@features/ManageMarketProfessionals/MarketProfessionalList.jsx"),
);
const MarketProfessionalDetails = lazyLoad(
  () =>
    import("@features/ManageMarketProfessionals/MarketProfessionalDetails.jsx"),
);
const AuctionList = lazyLoad(
  () => import("@features/ManageAuctions/AuctionList.jsx"),
);
const AuctionDetails = lazyLoad(
  () => import("@features/ManageAuctions/AuctionDetails.jsx"),
);
const UnderConstruction = lazyLoad(
  () => import("@features/UnderConstruction/index.jsx"),
);

const toast = useToast();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5, // 5 minutes
      gcTime: 1000 * 60 * 30, // 30 minutes
      refetchOnWindowFocus: false,
    },
  },
  queryCache: new QueryCache({
    onError: (error, query) => {
      const parsedQuery = z
        .object({
          meta: z.object({ errorMessage: z.string().optional() }).optional(),
        })
        .parse(query);
      const parsedError = z
        .object({
          body: z.object({ message: z.string().optional() }).optional(),
        })
        .parse(error);
      if (parsedQuery?.meta?.errorMessage) {
        toast.showError({ message: parsedQuery.meta.errorMessage });
      } else if (parsedError.body && parsedError.body.message) {
        toast.showError({ message: parsedError.body.message });
      }
    },
  }),
});

const HydrateAtoms = ({ children }: { children: React.ReactNode }) => {
  useHydrateAtoms([[queryClientAtom, queryClient as any]]);
  return children;
};

export function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <MantineProvider theme={AppTheme}>
          <JotaiProvider>
            <HydrateAtoms>
              <AppRoutes />
              <Notifications limit={3} position="top-center" />
            </HydrateAtoms>
          </JotaiProvider>
        </MantineProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

function AppRoutes() {
  return (
    <Suspense fallback={<LoaderFullscreen />}>
      <Routes>
        {/* Open Routes */}
        <Route path={RouteMap.Public.home} element={<Signin />} />
        <Route path={RouteMap.Public.signin} element={<Signin />} />
        <Route path={RouteMap.Public.signinHelp} element={<TroubleSignin />} />
        <Route path={RouteMap.Public.signup} element={<SignUp />} />

        {/* Protected Routes */}

        <Route
          path={RouteMap.Dashboard.index}
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path={RouteMap.MunicipalityDetails}
          element={
            <ProtectedRoute>
              <MunicipalityDetails />
            </ProtectedRoute>
          }
        />

        <Route
          path={RouteMap.IndividualInvestors.index}
          element={
            <ProtectedRoute>
              <IndividualInvestors />
            </ProtectedRoute>
          }
        />
        <Route
          path={RouteMap.IndividualInvestors.details}
          element={
            <ProtectedRoute>
              <IndividualInvestorsDetails />
            </ProtectedRoute>
          }
        />

        <Route
          path={RouteMap.InstitutionalInvestors.index}
          element={
            <ProtectedRoute>
              <InstitutionalInvestors />
            </ProtectedRoute>
          }
        />
        <Route
          path={RouteMap.InstitutionalInvestors.details}
          element={
            <ProtectedRoute>
              <InstitutionalInvestorDetails />
            </ProtectedRoute>
          }
        />

        <Route
          path={RouteMap.MarketProfessionals.index}
          element={
            <ProtectedRoute>
              <MarketProfessionals />
            </ProtectedRoute>
          }
        />
        <Route
          path={RouteMap.MarketProfessionals.details}
          element={
            <ProtectedRoute>
              <MarketProfessionalDetails />
            </ProtectedRoute>
          }
        />

        <Route
          path={RouteMap.ManageAuctions.index}
          element={
            <ProtectedRoute>
              <AuctionList />
            </ProtectedRoute>
          }
        />
        <Route
          path={RouteMap.ManageAuctions.details}
          element={
            <ProtectedRoute>
              <AuctionDetails />
            </ProtectedRoute>
          }
        />

        <Route
          path={RouteMap.UnderConstruction.index}
          element={
            <ProtectedRoute>
              <UnderConstruction />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Suspense>
  );
}
