import { ApiClient } from "@munivestor/ui/services";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { QueryKeys } from "@constants/QueryKeys.js";

interface Options {
  enabled?: boolean;
  retry?: number | boolean;
}

export function useUserMetadata(options?: Options) {
  const { enabled = true, retry = 3 } = options ?? {};

  const queryClient = useQueryClient();
  const queryRes = ApiClient.auth.getUserMetadata.useQuery(
    [QueryKeys.UserMetadata],
    undefined,
    {
      queryKey: [QueryKeys.UserMetadata],
      enabled,
      retry,
    },
  );

  const invalidate = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [QueryKeys.UserMetadata],
    });
  }, [queryClient]);

  const data = useMemo(() => {
    if (queryRes.data?.status !== 200) {
      return undefined;
    }

    return queryRes.data.body.data;
  }, [queryRes.data]);

  return {
    ...queryRes,
    data,
    invalidate,
  };
}
