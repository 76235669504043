import { integer, jsonb, pgTable } from "drizzle-orm/pg-core";
import { createCommonCols } from "../helpers/common-cols.js";
import { User } from "./user.entity.js";

export const UserSocialProfile = pgTable("user_social_profile", {
  ...createCommonCols("user_social_profile"),
  userId: integer("userId")
    .notNull()
    .references(() => User.id),
  favoriteMunicipalities: jsonb("favoriteMunicipalities")
    .notNull()
    .default([])
    .$type<Array<string>>(), // array of municipality uuids
  favoriteAuctions: jsonb("favoriteAuctions")
    .notNull()
    .default([])
    .$type<Array<string>>(), // array of municipality uuids
});

export type UserSocialProfileEntityType = typeof UserSocialProfile.$inferSelect;
