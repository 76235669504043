import { Municipality } from "@munivestor/models";
import { AccountStatusEnum } from "@munivestor/types";
import { createSelectSchema } from "drizzle-zod";
import { z } from "zod";

// ---- Municipality schema for listview ----
export const MunicipalityListItemSchema = createSelectSchema(Municipality, {
  status: z.nativeEnum(AccountStatusEnum),
})
  .pick({
    id: true,
    name: true,
    legalName: true,
    city: true,
    state: true,
    status: true,
  })
  .strict();

export type MunicipalityListItemType = z.infer<
  typeof MunicipalityListItemSchema
>;
