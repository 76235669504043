import { LoaderFullscreen } from "@munivestor/ui/components";
import type { ReactNode } from "react";
import { useEffect } from "react";
import { useSignOut } from "@hooks/auth/useSignOut.js";
import { useUserMetadata } from "@hooks/state/useUserMetadata.js";

export function ProtectedRoute({ children }: { children: ReactNode }) {
  const { data, isLoading, isError, error } = useUserMetadata({
    retry: 3,
  });
  const { mutate: signOut } = useSignOut();

  useEffect(() => {
    if (
      error?.status === 401 ||
      error?.status === 403 ||
      (isError && !isLoading && !data?.email)
    ) {
      console.log("You shouldn't be here. Signing out...");
      signOut();
    }
  }, [isError, data, isLoading, error]);

  if (isLoading) {
    return <LoaderFullscreen />;
  }

  if (error) {
    return <p>Unable to authenticate {(error as any).body?.message || ""}</p>;
  }

  return data ? <>{children}</> : null;
}
