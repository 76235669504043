import { Box, Button, Flex, Modal } from "@mantine/core";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { BodyText } from "../../atoms/Typography/BodyText.jsx";
import { Subtitle } from "../../atoms/Typography/Subtitle.js";

export interface MessageDialogProps {
  title?: string;
  children: React.ReactNode;
  buttonOkColor?: string;
  buttonOkText?: string;
}

export interface MessageDialogRef {
  getConfirmation: () => Promise<void>;
}

export const MessageDialog = forwardRef<MessageDialogRef, MessageDialogProps>(
  (props, ref) => {
    const {
      title = "Message",
      children,
      buttonOkColor = "brand",
      buttonOkText = "Ok",
    } = props;

    const onConfirm = useRef(() => {});

    const [isOpen, setOpen] = useState(false);

    useImperativeHandle(ref, () => ({
      getConfirmation: () => {
        // returns a promise that resolves to the confirmation value
        return new Promise((resolve) => {
          setOpen(true);
          onConfirm.current = () => {
            setOpen(false);
            resolve();
          };
        });
      },
    }));

    return (
      <>
        <Modal
          centered
          opened={isOpen}
          onClose={() => setOpen(false)}
          title={
            <Subtitle c="brand.8" fz="md" level={2}>
              {title}
            </Subtitle>
          }
        >
          <Flex direction="column" gap="lg">
            <Box flex={1}>
              {typeof children === "string" ? (
                <BodyText level={3}>{children}</BodyText>
              ) : (
                children
              )}
            </Box>
            <Flex gap="sm" justify="center">
              <Button color={buttonOkColor} onClick={onConfirm.current}>
                {buttonOkText}
              </Button>
            </Flex>
          </Flex>
        </Modal>
      </>
    );
  },
);
