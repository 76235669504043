import { Auction } from "@munivestor/models";
import {
  ApprovalLogSchema,
  AuctionCommunityImpactThemesSchema,
  AuctionMaturityStructureSchema,
  AuctionOfferingTypesEnum,
  AuctionRepaymentFrequencyEnum,
  AuctionSecurityTypesEnum,
  CreditRatingValuesFitchEnum,
  CreditRatingValuesKrollEnum,
  CreditRatingValuesMoodysEnum,
  CreditRatingValuesSnPEnum,
  LevelOfInterestEnum,
  MIN_BID_PRICE,
  PRICE_PER_BOND,
} from "@munivestor/types";
import { createSelectSchema } from "drizzle-zod";
import { z } from "zod";

// ---- Auction Profile ----
export const AuctionProfileSchema = createSelectSchema(Auction, {
  name: (schema) => schema.name.min(1, { message: "Name is required" }),
  baseCusip: (schema) =>
    schema.baseCusip.min(1, { message: "Base CUSIP is required" }),
  amount: z.coerce
    .number()
    .refine((val) => val > 0, { message: "Must be greater than 0" }),
  offeringType: (schema) =>
    schema.offeringType.refine(
      (val) =>
        Object.values(AuctionOfferingTypesEnum).find(
          (oType) => oType.toString() === val,
        ),
      { message: "Invalid" },
    ),
  redemptionDate: z.coerce.date().nullish(),
  redemptionPrice: (schema) =>
    schema.redemptionPrice
      .nullish()
      .transform((val) => (val !== "" ? val : undefined)),
  securityType: (schema) =>
    schema.securityType.refine(
      (val) =>
        Object.values(AuctionSecurityTypesEnum).find(
          (secType) => secType.toString() === val,
        ),
      { message: "Invalid" },
    ),
  repaymentFrequency: (schema) =>
    schema.repaymentFrequency.refine(
      (val) =>
        Object.values(AuctionRepaymentFrequencyEnum).find(
          (repaymentFreq) => repaymentFreq.toString() === val,
        ),
      { message: "Invalid" },
    ),
}).pick({
  amount: true,
  baseCusip: true,
  name: true,
  offeringType: true,
  purpose: true,
  redemptionDate: true,
  redemptionPrice: true,
  redemptionProvisions: true,
  repaymentFrequency: true,
  securityType: true,
  taxStatus: true,
  useOfFunds: true,
  repaymentSource: true,
});

export type AuctionProfileType = z.infer<typeof AuctionProfileSchema>;

// ---- Auction Professional ----
export const AuctionProfessionalSchema = z.object({
  id: z.number(),
  email: z.string().email(),
  name: z.string(),
  initials: z.string(),
  city: z.string(),
  state: z.string(),
});

export type AuctionProfessionalType = z.infer<typeof AuctionProfessionalSchema>;

// ---- Auction Ratings & Providers ----
export const AuctionRatingsProvidersSchema = createSelectSchema(Auction)
  .pick({
    fitchRating: true,
    moodysRating: true,
    snpRating: true,
    krollRating: true,
  })
  .extend({
    auctionCounsel: z.array(z.coerce.number()),
    auctionTrustee: z.array(z.coerce.number()),
    muniAdvisor: z.array(z.coerce.number()).optional(),
  });

export type AuctionRatingsProvidersType = z.infer<
  typeof AuctionRatingsProvidersSchema
>;

// used for validation on the backend
// for form validation see AuctionRatingsProvidersFormSchema below
export const AuctionRatingsProvidersCompletenessValidatorSchema =
  AuctionRatingsProvidersSchema.superRefine(validateRatingsProvider);

function validateRatingsProvider(
  val: Omit<
    AuctionRatingsProvidersType,
    "auctionCounsel" | "auctionTrustee" | "muniAdvisor"
  > & {
    auctionCounsel: Array<any>;
    auctionTrustee: Array<any>;
    muniAdvisor?: Array<any>;
  },
  ctx: z.RefinementCtx,
) {
  if (!val.fitchRating) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Fitch rating required",
      path: ["fitchRating"],
    });
  }
  if (
    !Object.values(CreditRatingValuesFitchEnum).includes(val.fitchRating as any)
  ) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Invalid Fitch rating",
      path: ["fitchRating"],
    });
  }

  if (!val.moodysRating) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Moodys rating required",
      path: ["moodysRating"],
    });
  }
  if (
    !Object.values(CreditRatingValuesMoodysEnum).includes(
      val.moodysRating as any,
    )
  ) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Invalid Moodys rating",
      path: ["moodysRating"],
    });
  }

  if (!val.snpRating) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "S&P rating required",
      path: ["snpRating"],
    });
  }
  if (
    !Object.values(CreditRatingValuesSnPEnum).includes(val.snpRating as any)
  ) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Invalid S&P rating",
      path: ["snpRating"],
    });
  }

  if (!val.krollRating) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Kroll rating required",
      path: ["krollRating"],
    });
  }
  if (
    !Object.values(CreditRatingValuesKrollEnum).includes(val.krollRating as any)
  ) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Invalid Kroll rating",
      path: ["krollRating"],
    });
  }

  if (val.auctionCounsel.length === 0) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "At least one counsel required",
      path: ["auctionCounsel"],
    });
  }
  if (val.auctionTrustee.length === 0) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "At least one trustee required",
      path: ["auctionTrustee"],
    });
  }
}

// For form validation on the UI
export const AuctionRatingsProvidersFormSchema =
  AuctionRatingsProvidersSchema.extend({
    auctionCounsel: z.array(z.string()),
    auctionTrustee: z.array(z.string()),
    muniAdvisor: z.array(z.string()).optional(),
  }).superRefine(validateRatingsProvider);

export type AuctionRatingsProvidersFormType = z.infer<
  typeof AuctionRatingsProvidersFormSchema
>;

// ---- Auction Order Priority ----
export const AuctionOrderPrioritySchema = createSelectSchema(Auction, {
  priorityNationalInd: z.coerce.number(),
  priorityNationalInst: z.coerce.number(),
  priorityStateInd: z.coerce.number(),
  priorityStateInst: z.coerce.number(),
  priorityInternationalInd: z.coerce.number(),
  priorityInternationalInst: z.coerce.number(),
}).pick({
  priorityNationalInd: true,
  priorityNationalInst: true,
  priorityStateInd: true,
  priorityStateInst: true,
  priorityInternationalInd: true,
  priorityInternationalInst: true,

  indMinAmount: true,
  indMaxAmount: true,
  instMinAmount: true,
  instMaxAmount: true,
});

export type AuctionOrderPriorityType = z.infer<
  typeof AuctionOrderPrioritySchema
>;

// This is used for form validation on the UI
export const AuctionOrderPriorityFormSchema =
  AuctionOrderPrioritySchema.superRefine((val, ctx) => {
    // check for duplicate priority values
    const priorityKeys = [
      "priorityStateInst",
      "priorityNationalInst",
      "priorityStateInd",
      "priorityNationalInd",
      "priorityInternationalInd",
      "priorityInternationalInst",
    ];
    for (let i = 0; i < priorityKeys.length; i++) {
      for (let j = i + 1; j < priorityKeys.length; j++) {
        if (
          // @ts-ignore
          val[priorityKeys[i]] === val[priorityKeys[j]] &&
          // @ts-ignore
          val[priorityKeys[i]] !== -1
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Duplicate value",
            path: [priorityKeys[i]],
          });
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Duplicate value",
            path: [priorityKeys[j]],
          });
        }
      }
    }
  });

export type AuctionOrderPriorityFormType = z.infer<
  typeof AuctionOrderPriorityFormSchema
>;

// ---- Auction Key Dates ----
export const AuctionKeyDatesSchema = createSelectSchema(Auction, {
  dated: z.coerce.date().nullable(),
  firstPrincipalPaymentDate: z.coerce.date().nullable(),
  finalMaturityDate: z.coerce.date().nullable(),
  marketingPeriodLaunchDate: z.coerce.date().nullable(),
  investorPresentationDate: z.coerce.date().nullable(),
  investorPresentationUrl: z.string().nullable(),
}).pick({
  marketingPeriodLaunchDate: true,
  investorPresentationDate: true,
  investorPresentationUrl: true,
  dated: true,
  firstPrincipalPaymentDate: true,
  finalMaturityDate: true,
});

export type AuctionKeyDatesType = z.infer<typeof AuctionKeyDatesSchema>;

// For form validation on the UI
export const AuctionDatesAndCommunitySchema = AuctionKeyDatesSchema.extend({
  communityImpactThemes: AuctionCommunityImpactThemesSchema.array(),
});

export type AuctionDatesAndCommunityType = z.infer<
  typeof AuctionDatesAndCommunitySchema
>;

export const AuctionDatesAndCommunityValidatorSchema =
  AuctionDatesAndCommunitySchema.superRefine((val, ctx) => {
    if (!val.dated) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Required",
        path: ["dated"],
      });
    }
    if (!val.firstPrincipalPaymentDate) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Required",
        path: ["firstPrincipalPaymentDate"],
      });
    }
    if (!val.finalMaturityDate) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Required",
        path: ["finalMaturityDate"],
      });
    }
    // final maturity date should be greater than first principal payment date
    if (
      val.firstPrincipalPaymentDate &&
      val.finalMaturityDate &&
      val.firstPrincipalPaymentDate >= val.finalMaturityDate
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Must be greater than first principal payment date",
        path: ["finalMaturityDate"],
      });
    }
  });

export const UpdateAuctionDatesAndCommunityBodySchema =
  AuctionDatesAndCommunitySchema.extend({
    dated: z.string().nullable(),
    firstPrincipalPaymentDate: z.string().nullable(),
    finalMaturityDate: z.string().nullable(),
    marketingPeriodLaunchDate: z.string().nullable(),
    investorPresentationDate: z.string().nullable(),
  });

export type UpdateAuctionDatesAndCommunityBodyType = z.infer<
  typeof UpdateAuctionDatesAndCommunityBodySchema
>;

// ---- Auction Documents ----
export const AuctionDocumentsRequestSchema = z.object({
  investorPresentation: z.instanceof(File).optional(),
  preliminaryOfficialStatement: z.instanceof(File).optional(),
  docOther1: z.instanceof(File).nullable().optional(),
  docOther2: z.instanceof(File).nullable().optional(),
});

export type AuctionDocumentsRequestType = z.infer<
  typeof AuctionDocumentsRequestSchema
>;

export const AuctionDocumentsResponseSchema = z.object({
  investorPresentation: z
    .object({
      id: z.number(),
      name: z.string(),
      url: z.string().url(),
    })
    .optional(),
  preliminaryOfficialStatement: z
    .object({
      id: z.number(),
      name: z.string(),
      url: z.string().url(),
    })
    .optional(),
  docOther1: z
    .object({
      id: z.number(),
      name: z.string(),
      url: z.string().url(),
    })
    .optional(),
  docOther2: z
    .object({
      id: z.number(),
      name: z.string(),
      url: z.string().url(),
    })
    .optional(),
});

export type AuctionDocumentsResponseType = z.infer<
  typeof AuctionDocumentsResponseSchema
>;

export const AuctionDocumentTypesSchema = z.enum([
  "investorPresentation",
  "preliminaryOfficialStatement",
  "docOther1",
  "docOther2",
]);

export type AuctionDocumentTypes = z.infer<typeof AuctionDocumentTypesSchema>;

export const AuctionMaturityStructureFormSchema = z
  .object({
    schedule: AuctionMaturityStructureSchema,
  })
  .superRefine((val, ctx) => {
    if (val.schedule.length === 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "At least one maturity required",
        path: ["schedule"],
      });
    }

    // minimum principal amounts is MIN_BID_PRICE
    let index = val.schedule.findIndex(
      (row) => !row.principalAmount || row.principalAmount < MIN_BID_PRICE,
    );
    if (index !== -1) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Minimum amount is $${MIN_BID_PRICE}`,
        path: ["schedule", index, "principalAmount"],
      });
      return z.NEVER;
    }
    // all principal amounts must be greater than or equal to 0
    index = val.schedule.findIndex((row) => row.principalAmount < 0);
    if (index !== -1) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Must be greater than or equal to 0",
        path: ["schedule", index, "principalAmount"],
      });
      return z.NEVER;
    }

    // all principal amounts must be multiples of PRICE_PER_BOND
    index = val.schedule.findIndex(
      (row) => row.principalAmount % PRICE_PER_BOND !== 0,
    );
    if (index !== -1) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Must be a multiple of $${PRICE_PER_BOND}`,
        path: ["schedule", index, "principalAmount"],
      });
      return z.NEVER;
    }
  });

export type AuctionMaturityStructureFormType = z.infer<
  typeof AuctionMaturityStructureFormSchema
>;

// ---- Auction Details ----
export const AuctionDetailsSchema = createSelectSchema(Auction)
  .pick({
    offeringType: true,
    priorityInternationalInd: true,
    priorityInternationalInst: true,
    priorityNationalInd: true,
    priorityNationalInst: true,
    priorityStateInd: true,
    priorityStateInst: true,
    status: true,
    uuid: true,
  })
  .extend({
    profile: AuctionProfileSchema,
    ratingsProviders: AuctionRatingsProvidersSchema,
    orderPriority: AuctionOrderPrioritySchema,
    keyDates: AuctionKeyDatesSchema,
    communityImpactThemes: z.array(AuctionCommunityImpactThemesSchema),
    documents: AuctionDocumentsResponseSchema,
    maturityStructure: AuctionMaturityStructureSchema,
    approvalHistory: ApprovalLogSchema.array(),
    startDate: z.coerce.date().nullable(),
    closeDate: z.coerce.date().nullable(),
    municipality: z.object({
      uuid: z.string(),
      name: z.string(),
      type: z.string(),
      city: z.string(),
      state: z.string(),
    }),
  });

export type AuctionDetailsType = z.infer<typeof AuctionDetailsSchema>;

// ---- Auction Public Details ----
export const AuctionPublicDetailsSchema = AuctionDetailsSchema.omit({
  approvalHistory: true,
});

export type AuctionPublicDetailsType = z.infer<
  typeof AuctionPublicDetailsSchema
>;

// ---- Auction Details for Investor----
export const AuctionInvestorDetailsSchema = AuctionPublicDetailsSchema.extend({
  canBid: z.boolean(),
});

export type AuctionInvestorDetailsType = z.infer<
  typeof AuctionInvestorDetailsSchema
>;

// ---- Own Auction List Item ----
export const AuctionListItemSchema = createSelectSchema(Auction)
  .pick({
    uuid: true,
    name: true,
    status: true,
    amount: true,
    securityType: true,
    repaymentFrequency: true,
    marketingPeriodLaunchDate: true,
    createdAt: true,
    updatedAt: true,
  })
  .extend({
    isInMarketingPhase: z.boolean().default(false),
    isReadyForGoLive: z.boolean().default(false),
    municipalityName: z.string().optional(),
  });

export type AuctionListItemType = z.infer<typeof AuctionListItemSchema>;

// ---- All Auctions List Item ----
export const AllAuctionsListItemSchema = createSelectSchema(Auction)
  .pick({
    uuid: true,
    name: true,
    status: true,
    amount: true,
    createdAt: true,
    updatedAt: true,
  })
  .extend({
    muniName: z.string(),
    muniId: z.string(),
    canBid: z.boolean(),
  });

export type AllAuctionsListItemType = z.infer<typeof AllAuctionsListItemSchema>;

// -- Auction List Filter --

export const AuctionListAdvFilterSchema = z.object({
  // advanced search filters
  comImpactTheme: z.array(z.string()).optional(),
  taxStatus: z.array(z.string()).optional(),
  repaymentFrequency: z.array(z.string()).optional(),
});

export type AuctionListAdvFilterType = z.infer<
  typeof AuctionListAdvFilterSchema
>;

export const AuctionListFilterSchema = z
  .object({
    cusip: z.string().optional(),
    muniName: z.string().optional(),
    muniType: z.string().nullable().optional(),
    city: z.string().optional(),
    state: z.string().nullable().optional(),
  })
  .merge(AuctionListAdvFilterSchema);

export type AuctionListFilterType = z.infer<typeof AuctionListFilterSchema>;
// ---- Auction List Item ----
export const AuctionListItemForInvestorSchema = createSelectSchema(
  Auction,
).pick({
  amount: true,
  createdAt: true,
  dated: true,
  finalMaturityDate: true,
  firstPrincipalPaymentDate: true,
  name: true,
  repaymentFrequency: true,
  securityType: true,
  status: true,
  updatedAt: true,
  uuid: true,
});

export type AuctionListItemForInvestorType = z.infer<
  typeof AuctionListItemForInvestorSchema
>;

// ---- Auction Feedback ----
export const SubmitFeedbackRequestSchema = z.object({
  alertMe: z.boolean().default(false),
  feedback: z.string().min(1),
  levelOfInterest: z
    .string()
    .refine((val) => Object.values(LevelOfInterestEnum).includes(val as any), {
      message: "Invalid",
    }),
});

export type SubmitFeedbackRequestType = z.infer<
  typeof SubmitFeedbackRequestSchema
>;

// ---- Auction Inventory ----
export const AuctionInventorySchema = z.object({
  uuid: z.string().uuid(),
  total: z.number(),
  remaining: z.number(),
  investments: z
    .object({
      maturityDate: z.coerce.date(),
      investmentAmount: z.coerce.number(),
      inventoryUsed: z.coerce.number(),
    })
    .array(),
});

export type AuctionInventoryType = z.infer<typeof AuctionInventorySchema>;

export const AuctionInvestmentsFormSchema = AuctionInventorySchema.pick({
  investments: true,
}).superRefine((val, ctx) => {
  // at least one investment required
  if (val.investments.length === 0) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "At least one investment required",
      path: ["investments", 0, "investmentAmount"],
    });
    return z.NEVER;
  }
  // minimum investment amount is MIN_BID_PRICE
  if (
    val.investments.every(
      (investment) =>
        !investment.investmentAmount ||
        investment.investmentAmount < MIN_BID_PRICE,
    )
  ) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `Minimum amount is $${MIN_BID_PRICE}`,
      path: ["investments", 0, "investmentAmount"],
    });
    return z.NEVER;
  }
  // all investments must be greater than or equal to 0
  let index = val.investments.findIndex(
    (investment) => investment.investmentAmount < 0,
  );
  if (index !== -1) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Must be greater than or equal to 0",
      path: ["investments", index, "investmentAmount"],
    });
    return z.NEVER;
  }

  // all investments must be multiples of PRICE_PER_BOND
  index = val.investments.findIndex(
    (investment) => investment.investmentAmount % PRICE_PER_BOND !== 0,
  );
  if (index !== -1) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `Must be a multiple of $${PRICE_PER_BOND}`,
      path: ["investments", index, "investmentAmount"],
    });
    return z.NEVER;
  }
});

export type AuctionInvestmentsFormType = z.infer<
  typeof AuctionInvestmentsFormSchema
>;

export const AuctionBidResponseSchema = z.object({
  createdAt: z.coerce.date(),
  bidId: z.string().uuid(),
  auctionId: z.string().uuid(),
  auctionName: z.string(),
  investments: AuctionInventorySchema.shape.investments,
});

export type AuctionBidResponseType = z.infer<typeof AuctionBidResponseSchema>;

export const AuctionSubscriptionResponseSchema = z.object({
  auctionId: z.string().uuid(),
  isSubscribedToAuctionStatusChange: z.boolean(),
  isSubscribedToInvestorPresentation: z.boolean(),
});

export type AuctionSubscriptionResponseType = z.infer<
  typeof AuctionSubscriptionResponseSchema
>;

export const AuctionBidCheckSchema = createSelectSchema(Auction)
  .pick({
    offeringType: true,
    priorityInternationalInd: true,
    priorityInternationalInst: true,
    priorityNationalInd: true,
    priorityNationalInst: true,
    priorityStateInd: true,
    priorityStateInst: true,
    status: true,
    uuid: true,
  })
  .extend({
    municipalityState: z.string().nullable(),
  });
export type AuctionBidCheckType = z.infer<typeof AuctionBidCheckSchema>;
