import { index, pgTable, text } from "drizzle-orm/pg-core";
import { createCommonCols } from "../helpers/common-cols.js";

export const User = pgTable(
  "user",
  {
    ...createCommonCols("user"),
    uid: text("uid").notNull().unique(),
    email: text("email").notNull().unique(),
    firstName: text("firstName").notNull(),
    lastName: text("lastName").notNull(),
    businessDesignation: text("businessDesignation"),
    phone: text("phone"),
    phoneExtension: text("phoneExtension"),
    address: text("address"),
    city: text("city"),
    state: text("state"),
    zipCode: text("zipCode"),
  },
  (table) => ({
    uuidIndex: index("user_uuid_idx").on(table.uuid),
    emailIndex: index("user_email_idx").on(table.email),
  }),
);

export type UserEntityType = typeof User.$inferSelect;
