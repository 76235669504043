import { z } from "zod";
import { MessageErrorSchema } from "../schemas/http-response.schema.js";
import {
  MunicipalityDetailsForInvestorSchema,
  MunicipalityListFilterSchema,
  MunicipalityListViewItemSchema,
} from "../schemas/municipality.schema.js";
import { StatusCodes } from "../utils/http-status-codes.js";
import { apiContract } from "./contract.js";

export const InvestorRouter = apiContract.router(
  {
    listMunicipalities: {
      method: "GET",
      path: "municipalities",
      query: MunicipalityListFilterSchema,
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: z.array(MunicipalityListViewItemSchema),
        }),
        [StatusCodes.BadRequest]: MessageErrorSchema,
        [StatusCodes.Forbidden]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "List of municipalities",
    },

    getMuniDetails: {
      method: "GET",
      path: "municipalities/:uuid",
      pathParams: z.object({
        uuid: z.string(),
      }),
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: MunicipalityDetailsForInvestorSchema,
        }),
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.Forbidden]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "List of municipalities",
    },
  },

  // ---- Options ----
  {
    pathPrefix: "/api/investor/",
    strictStatusCodes: true,
  },
);
