import { boolean, integer, pgTable } from "drizzle-orm/pg-core";
import { createCommonCols } from "../helpers/common-cols.js";
import { Auction } from "./auction.entity.js";
import { User } from "./user.entity.js";

export const AuctionSubscriber = pgTable("auction_subscriber", {
  ...createCommonCols("auction_subscriber"),
  userId: integer("userId")
    .notNull()
    .references(() => User.id),
  auctionId: integer("auctionId")
    .notNull()
    .references(() => Auction.id),
  isSubscribedToAuctionStatusChange: boolean(
    "isSubscribedToAuctionStatusChange",
  )
    .notNull()
    .default(false),
  isSubscribedToInvestorPresentation: boolean(
    "isSubscribedToInvestorPresentation",
  )
    .notNull()
    .default(false),
});

export type AuctionSubscriberEntityType = typeof AuctionSubscriber.$inferSelect;
