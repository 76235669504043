import { notifications } from "@mantine/notifications";
import { createElement } from "react";
import { FaCheck, FaExclamation, FaTimes } from "react-icons/fa";

const DELAY = 4000;

interface ToastProps {
  title?: string;
  message: string;
  icon?: React.ReactNode;
  onClose?: () => void;
}

export function useToast() {
  return {
    showError: (params: ToastProps) =>
      notifications.show({
        autoClose: DELAY,
        color: "red",
        icon: params.icon ?? createElement(FaTimes),
        title: params.title,
        message: params.message,
        onClose: () => params.onClose?.(),
      }),

    showSuccess: (params: ToastProps) =>
      notifications.show({
        autoClose: DELAY,
        color: "green",
        icon: params.icon ?? createElement(FaCheck),
        title: params.title,
        message: params.message,
        onClose: () => params.onClose?.(),
      }),

    showInfo: (params: ToastProps) =>
      notifications.show({
        autoClose: DELAY,
        color: "tertiary",
        icon: params.icon ?? createElement(FaExclamation),
        title: params.title,
        message: params.message,
        onClose: () => params.onClose?.(),
      }),
  };
}
