export const AuctionCommunityImpactThemes = [
  {
    label: "Affordable Housing",
    value: "affordable_housing",
  },
  {
    label: "Nature based solutions",
    value: "nature_based_solutions",
  },
  {
    label: "Clean Energy",
    value: "clean_energy",
  },
  {
    label: "Walkable cities",
    value: "walkable_cities",
  },
  {
    label: "Clean transportation",
    value: "clean_transportation",
  },
  {
    label: "Water management",
    value: "water_management",
  },
  {
    label: "Healthy communities",
    value: "healthy_communities",
  },
];

export const MARKETING_PERIOD_DAYS = 30;
export const MARKETING_PERIOD_TEST_MINUTES = 30;

export const PRICE_PER_BOND = 1;
export const MIN_BID_PRICE = 25;
export const INST_INVESTOR_MIN_BID_PRICE = 25000;
