import {
  AccountStatusEnum,
  UserRoleEnum,
  UserTypeEnum,
} from "@munivestor/types";
import { z } from "zod";

// ---- Firebase Config ----
export const FirebaseConfigSchema = z.object({
  apiKey: z.string(),
  authDomain: z.string(),
  projectId: z.string(),
  storageBucket: z.string(),
  messagingSenderId: z.string(),
  appId: z.string(),
  measurementId: z.string().optional(),
});

export type FirebaseConfigType = z.infer<typeof FirebaseConfigSchema>;

// ---- Signup ----
export const FirebaseSignupSchema = z.object({
  email: z.string().email(),
  password: z.string(),
});

export type FirebaseSignupType = z.infer<typeof FirebaseSignupSchema>;

// ---- Municipality Signup Payload ----
export const SignupMunicipalityPayloadSchema = z.object({
  muniName: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email(),
  inviteCode: z.string().optional(),
  idToken: z.string(),
});

export type SignupMunicipalityPayloadType = z.infer<
  typeof SignupMunicipalityPayloadSchema
>;

// ---- Investor Signup Payload ----
export const SignupInvestorPayloadSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email(),
  inviteCode: z.string().optional(),
  idToken: z.string(),
});

export type SignupInvestorPayloadType = z.infer<
  typeof SignupInvestorPayloadSchema
>;

// ---- Institutional Investor Signup Payload ----
export const SignupInsitutionalInvestorPayloadSchema = z.object({
  institutionName: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email(),
  inviteCode: z.string().optional(),
  idToken: z.string(),
});

export type SignupInsitutionalInvestorPayloadType = z.infer<
  typeof SignupInsitutionalInvestorPayloadSchema
>;

// ---- Market professional Signup Payload ----
export const SignupMarketProfPayloadSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email(),
  inviteCode: z.string().optional(),
  idToken: z.string(),
});

export type SignupMarketProfPayloadType = z.infer<
  typeof SignupMarketProfPayloadSchema
>;

// ---- Signin ----
export const FirebaseSigninSchema = z.object({
  email: z.string().email(),
  password: z.string(),
});

export type FirebaseSigninType = z.infer<typeof FirebaseSigninSchema>;

// ---- User Metadata ----
export const UserMetadataSchema = z.object({
  id: z.number(),
  uuid: z.string(),
  email: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  role: z.nativeEnum(UserRoleEnum),
  userType: z.nativeEnum(UserTypeEnum),
  city: z.string().nullable(),
  state: z.string().nullable(),
  onboardingStatus: z
    .nativeEnum(AccountStatusEnum)
    .default(AccountStatusEnum.New),
});

export type UserMetadataType = z.infer<typeof UserMetadataSchema>;

// ---- Firebase Error ----
export const FirebaseErrorSchema = z.object({
  message: z.string(),
  code: z.string(),
  name: z.string(),
});

export type FirebaseErrorType = z.infer<typeof FirebaseErrorSchema>;
