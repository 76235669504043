import { boolean, serial, timestamp, uuid } from "drizzle-orm/pg-core";

export const createCommonCols = (tableName: string) => ({
  id: serial("id").primaryKey(),
  uuid: uuid("uuid").defaultRandom().notNull().unique(`${tableName}_uuid`),
  createdAt: timestamp("createdAt", {
    mode: "date",
    withTimezone: true,
  })
    .notNull()
    .defaultNow(),
  updatedAt: timestamp("updatedAt", {
    mode: "date",
    withTimezone: true,
  })
    .notNull()
    .$onUpdate(() => new Date())
    .defaultNow(),
  isActive: boolean("isActive").default(true),
});
