import { z } from "zod";
import { MarketProfessionalConnectionResponseSchema } from "../index.js";
import { MessageErrorSchema } from "../schemas/http-response.schema.js";
import { StatusCodes } from "../utils/http-status-codes.js";
import { apiContract } from "./contract.js";

export const MarketProfessionalRouter = apiContract.router(
  {
    myConnections: {
      method: "GET",
      path: "my-connections",
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: z.array(MarketProfessionalConnectionResponseSchema),
        }),
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.Forbidden]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Get all auctions associated with the professional",
    },
  },

  // ---- Options ----
  {
    pathPrefix: "/api/market-professional/",
    strictStatusCodes: true,
  },
);
