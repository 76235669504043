import {
  MuniCreditRating,
  MuniPersonnel,
  Municipality,
  MarketNews,
} from "@munivestor/models";
import {
  AccountStatusEnum,
  CreditRatingValuesFitchEnum,
  CreditRatingValuesKrollEnum,
  CreditRatingValuesMoodysEnum,
  CreditRatingValuesSnPEnum,
  MunicipalityAttachmentTypeEnum,
  MunicipalityTypeEnum,
  MunicipalityViewOptionsEnum,
  StatsTimeFrameEnum,
  USStates,
} from "@munivestor/types";
import { createInsertSchema, createSelectSchema } from "drizzle-zod";
import { z } from "zod";
import { AuctionListItemForInvestorSchema } from "./auction.schema.js";

// ---- Onboarding 1: Overview ----
export const MunicipalityOverviewFormSchema = createInsertSchema(Municipality, {
  name: (schema) =>
    schema.name.min(1, { message: "Municipality Name is required" }),
  legalName: (schema) =>
    schema.legalName.min(1, { message: "Legal name is required" }),
  city: (schema) => schema.city.min(1, { message: "City is required" }),
  state: (schema) =>
    schema.state
      .min(1, { message: "State is required" })
      .refine((val) => USStates.find((state) => state.id === val), {
        message: "Invalid state",
      }),

  zipCode: (schema) =>
    schema.zipCode.min(1, { message: "Zip code is required" }),

  type: (schema) =>
    schema.type.refine(
      (val: string) => Object.values(MunicipalityTypeEnum).includes(val as any),
      { message: "Invalid municipality type" },
    ),

  summary: (schema) => schema.summary.optional(),
  website: (schema) => schema.website.optional(),
  emmaIssuerUrl: (schema) =>
    schema.emmaIssuerUrl.url().optional().or(z.literal("")), // zod doesn't support optional url
  status: (schema) =>
    schema.status.refine(
      (val: string) => Object.values(AccountStatusEnum).includes(val as any),
      { message: "Invalid municipality status" },
    ),
})
  .pick({
    name: true,
    legalName: true,
    city: true,
    state: true,
    zipCode: true,
    type: true,
    summary: true,
    website: true,
    emmaIssuerUrl: true,
    status: true,
  })
  .strict();

export type MunicipalityOverviewFormType = z.infer<
  typeof MunicipalityOverviewFormSchema
>;

// ---- Onboarding 1a: Banner Upload ----
export const MunicipalityBannerUploadSchema = z.object({
  bannerImage: z.custom<File>().optional(),
});

export type MunicipalityBannerUploadType = z.infer<
  typeof MunicipalityBannerUploadSchema
>;

// ---- Onboarding 2: Docs and Links ----
export const MunicipalityDocsRequestSchema = z
  .object({
    enablementDocs: z.array(z.custom<File>()).optional(),
  })
  .strict();

export type MunicipalityDocsRequestType = z.infer<
  typeof MunicipalityDocsRequestSchema
>;

// ---- Municipality Financial & Economic Profile ----
export const MunicipalityFinEconProfileSchema = createInsertSchema(
  Municipality,
  {
    emmaIssuerUrl: (schema) =>
      schema.emmaIssuerUrl.url({ message: "Invalid URL" }).or(z.literal("")),
    censusDataUrl: (schema) =>
      schema.censusDataUrl.url({ message: "Invalid URL" }).or(z.literal("")),
    sifmaUrl: (schema) =>
      schema.sifmaUrl.url({ message: "Invalid URL" }).or(z.literal("")),
  },
).pick({
  emmaIssuerUrl: true,
  censusDataUrl: true,
  sifmaUrl: true,
});

export type MunicipalityFinEconProfileType = z.infer<
  typeof MunicipalityFinEconProfileSchema
>;

// ---- Onboarding 3: Muni Key Personnel ----
export const MuniPersonnelFormSchema = createInsertSchema(MuniPersonnel, {
  name: (schema) => schema.name.min(1, { message: "Name is required" }),
  title: (schema) => schema.title.min(1, { message: "Title is required" }),
  bio: (schema) => schema.bio.min(1, { message: "Bio is required" }),
  phone: (schema) => schema.phone.min(1, { message: "Phone is required" }),
  phoneExtension: (schema) => schema.phoneExtension.optional(),
  email: (schema) => schema.email.email({ message: "Invalid email" }),
  linkedInUrl: (schema) =>
    schema.linkedInUrl.url({ message: "Invalid URL" }).or(z.literal("")),
  rank: (schema) => schema.rank.int(),
}).pick({
  id: true,
  name: true,
  title: true,
  bio: true,
  phone: true,
  phoneExtension: true,
  email: true,
  linkedInUrl: true,
  rank: true,
  showOnProfile: true,
});

export type MuniPersonnelFormType = z.infer<typeof MuniPersonnelFormSchema>;

// ---- Muni Credit Rating Form ----
export const MuniCreditRatingFormSchema = createInsertSchema(MuniCreditRating, {
  revStream: (schema) =>
    schema.revStream.min(1, { message: "Revenue stream is required" }),
  fitchRating: z
    .string()
    .refine((val) => val !== "", {
      message: "Fitch rating required",
    })
    .refine(
      (val) => Object.values(CreditRatingValuesFitchEnum).includes(val as any),
      {
        message: "Invalid Fitch rating",
      },
    ),
  moodysRating: z
    .string()
    .refine((val) => val !== "", {
      message: "Moody's rating required",
    })
    .refine(
      (val) => Object.values(CreditRatingValuesMoodysEnum).includes(val as any),
      {
        message: "Invalid Moody's rating",
      },
    ),
  snpRating: z
    .string()
    .refine((val) => val !== "", {
      message: "S&P rating required",
    })
    .refine(
      (val) => Object.values(CreditRatingValuesSnPEnum).includes(val as any),
      {
        message: "Invalid S&P rating",
      },
    ),
  krollRating: z
    .string()
    .refine((val) => val !== "", {
      message: "Kroll rating required",
    })
    .refine(
      (val) => Object.values(CreditRatingValuesKrollEnum).includes(val as any),
      { message: "Invalid Kroll rating" },
    )
    .refine((val) => val.length, { message: "Kroll rating required" }),
}).pick({
  id: true,
  revStream: true,
  description: true,
  fitchRating: true,
  moodysRating: true,
  snpRating: true,
  krollRating: true,
});

export type MuniCreditRatingFormType = z.infer<
  typeof MuniCreditRatingFormSchema
>;

// ---- Municipality Onboarding Info Response ----
export const MunicipalityOnboardingInfoResponseSchema = z.strictObject({
  overview: createSelectSchema(Municipality).pick({
    id: true,
    name: true,
    legalName: true,
    city: true,
    state: true,
    zipCode: true,
    type: true,
    summary: true,
    website: true,
    emmaIssuerUrl: true,
    status: true,
  }),
  attachments: z.array(
    z.strictObject({
      id: z.number(),
      name: z.string().min(1, { message: "Name is required" }),
      type: z.nativeEnum(MunicipalityAttachmentTypeEnum),
      url: z
        .string()
        .url({ message: "Invalid URL" })
        .min(1, { message: "URL is required" }),
    }),
  ),
  keyPersonnel: z.array(createSelectSchema(MuniPersonnel)),
  finEconProfile: MunicipalityFinEconProfileSchema,
  creditRatings: z.array(createSelectSchema(MuniCreditRating)),
});

export type MunicipalityOnboardingInfoResponseType = z.infer<
  typeof MunicipalityOnboardingInfoResponseSchema
>;

// ---- Municipality Listview Item ----
export const MunicipalityListViewItemSchema = createSelectSchema(Municipality)
  .pick({
    uuid: true,
    name: true,
    city: true,
    state: true,
    type: true,
    summary: true,
    status: true,
  })
  .extend({
    numActiveAuctions: z.number(),
  });

export type MunicipalityListViewItemType = z.infer<
  typeof MunicipalityListViewItemSchema
>;

export const MunicipalityListFilterSchema = z.object({
  name: z.string().optional(),
  type: z.string().nullable().optional(),
  city: z.string().optional(),
  state: z.string().nullable().optional(),
});

export type MunicipalityListFilterType = z.infer<
  typeof MunicipalityListFilterSchema
>;

// ---- Municipality Details for investor ----
export const MunicipalityDetailsForInvestorSchema = z.strictObject({
  overview: createSelectSchema(Municipality).pick({
    id: true,
    name: true,
    legalName: true,
    city: true,
    state: true,
    zipCode: true,
    type: true,
    summary: true,
    website: true,
    emmaIssuerUrl: true,
    status: true,
  }),
  attachments: z.array(
    z.strictObject({
      id: z.number(),
      name: z.string(),
      type: z.string(),
      createdAt: z.date(),
      url: z
        .string()
        .url({ message: "Invalid URL" })
        .min(1, { message: "URL is required" }),
    }),
  ),
  keyPersonnel: z.array(createSelectSchema(MuniPersonnel)),
  finEconProfile: MunicipalityFinEconProfileSchema,
  creditRatings: z.array(createSelectSchema(MuniCreditRating)),
  activeAuctions: z.array(AuctionListItemForInvestorSchema),
  upcomingAuctions: z.array(AuctionListItemForInvestorSchema),
  outstandingBids: z.array(AuctionListItemForInvestorSchema),
});

export type MunicipalityDetailsForInvestorType = z.infer<
  typeof MunicipalityDetailsForInvestorSchema
>;

// ---- Municipality Info for dashboard ----
export const MunicipalityInfoForDashboardSchema = z.strictObject({
  name: z.string(),
  city: z.string(),
  state: z.string(),
  type: z.string(),
});

export type MunicipalityInfoForDashboardType = z.infer<
  typeof MunicipalityInfoForDashboardSchema
>;

// ---- Market News ----
export const MarketNewsSchema = createSelectSchema(MarketNews)
  .pick({
    uuid: true,
    title: true,
    url: true,
    content: true,
    author: true,
    createdAt: true,
  })
  .extend({
    imageUrl: z.string().optional(),
    isEditable: z.boolean().optional(),
  });

export type MarketNewsType = z.infer<typeof MarketNewsSchema>;

export const CreateMarketNewsSchema = createInsertSchema(MarketNews, {
  title: (schema) => schema.title.min(1, { message: "Title is required" }),
  content: (schema) =>
    schema.content.min(1, { message: "Content is required" }),
  url: (schema) => schema.url.url({ message: "Invalid URL" }),
})
  .pick({
    title: true,
    content: true,
    author: true,
    url: true,
  })
  .extend({ image: z.custom<File>().optional() });

export type CreateMarketNewsType = z.infer<typeof CreateMarketNewsSchema>;

export const MunicipalityStatsResponseSchema = z.object({
  totalInvestmentStats: z.object({
    totalInvestment: z.number(),
    changePercentage: z.number(),
  }),
  totalInvestmentVolumeStats: z.object({
    totalInvestmentVolume: z.number(),
    changePercentage: z.number(),
  }),
  averageIndividualInvestmentStats: z.object({
    averageInvestment: z.number(),
    changePercentage: z.number(),
  }),
  averageInstitutionalInvestmentStats: z.object({
    averageInvestment: z.number(),
    changePercentage: z.number(),
  }),
  liveBondIssuanceStats: z.object({
    count: z.number(),
    changePercentage: z.number(),
  }),
  bondIssuanceStats: z.object({
    count: z.number(),
  }),
});

export type MunicipalityStatsResponseType = z.infer<
  typeof MunicipalityStatsResponseSchema
>;

export const MunicipalityStatsRequestSchema = z.object({
  isOnlyMine: z.coerce.boolean(),
  timeFrame: z.enum([
    StatsTimeFrameEnum.Daily,
    StatsTimeFrameEnum.Weekly,
    StatsTimeFrameEnum.Monthly,
  ]),
});

export type MunicipalityStatsRequestType = z.infer<
  typeof MunicipalityStatsRequestSchema
>;

// ---- Municipality Stats Filter ----
export const MunicipalityStatsFilterSchema = z.object({
  municiaplityViewType: z.enum([
    MunicipalityViewOptionsEnum.ViewAllMunivestorDeals,
    MunicipalityViewOptionsEnum.ViewOnlyMyMunicipality,
  ]),
  timeFrame: z.enum([
    StatsTimeFrameEnum.Daily,
    StatsTimeFrameEnum.Weekly,
    StatsTimeFrameEnum.Monthly,
  ]),
});

export type MunicipalityStatsFilterType = z.infer<
  typeof MunicipalityStatsFilterSchema
>;
