import type { ApprovalLogType } from "@munivestor/types";
import { AccountStatusEnum } from "@munivestor/types";
import { index, jsonb, pgTable, text } from "drizzle-orm/pg-core";
import { createCommonCols } from "../helpers/common-cols.js";
import { accountStatusPgEnum, muniTypesPgEnum } from "../helpers/enums.js";

export const Municipality = pgTable(
  "municipality",
  {
    ...createCommonCols("municipality"),
    name: text("name").notNull(),
    legalName: text("legalName"),
    city: text("city"),
    state: text("state"),
    zipCode: text("zipCode"),
    type: muniTypesPgEnum("type"),
    summary: text("summary"),
    website: text("website"),
    emmaIssuerUrl: text("emmaIssuerUrl"),
    censusDataUrl: text("censusDataUrl"),
    sifmaUrl: text("sifmaUrl"),
    status: accountStatusPgEnum("status")
      .notNull()
      .default(AccountStatusEnum.New),
    approvalHistory: jsonb("approvalHistory")
      .notNull()
      .default([])
      .$type<Array<ApprovalLogType>>(),
  },
  (table) => ({
    uuidIndex: index("municipality_uuid_idx").on(table.uuid),
  }),
);

export type MunicipalityEntityType = typeof Municipality.$inferSelect;
