import { pgTable, integer, text } from "drizzle-orm/pg-core";
import { createCommonCols } from "../helpers/common-cols.js";
import {
  creditRatingValuesFitchPgEnum,
  creditRatingValuesKrollPgEnum,
  creditRatingValuesMoodysPgEnum,
  creditRatingValuesSnPPgEnum,
} from "../helpers/enums.js";
import { Municipality } from "./municipality.entity.js";

export const MuniCreditRating = pgTable("muni_credit_rating", {
  ...createCommonCols("muni_credit_rating"),
  municipalityId: integer("municipalityId")
    .notNull()
    .references(() => Municipality.id),
  revStream: text("revStream").notNull(),
  description: text("description"),
  fitchRating: creditRatingValuesFitchPgEnum("fitchRating").notNull(),
  moodysRating: creditRatingValuesMoodysPgEnum("moodysRating").notNull(),
  snpRating: creditRatingValuesSnPPgEnum("snpRating").notNull(),
  krollRating: creditRatingValuesKrollPgEnum("krollRating"), // TODO: notNull()?
});

export type MuniCreditRatingEntityType = typeof MuniCreditRating.$inferSelect;
