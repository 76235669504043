import { pgTable, integer, text } from "drizzle-orm/pg-core";
import { createCommonCols } from "../helpers/common-cols.js";
import { Municipality } from "./municipality.entity.js";
import { UploadedFile } from "./uploaded-file.entity.js";

export const MarketNews = pgTable("market_news", {
  ...createCommonCols("market_news"),
  municipalityId: integer("municipalityId")
    .notNull()
    .references(() => Municipality.id),
  title: text("title").notNull(),
  url: text("url").notNull(),
  author: text("author"),
  content: text("content"),
  imageId: integer("imageId")
    .unique()
    .references(() => UploadedFile.id),
});

export type MarketNewsEntityType = typeof MarketNews.$inferSelect;
