export enum UserTypeEnum {
  Municipality = "municipality",
  InstitutionalInvestor = "institutional-investor",
  IndividualInvestor = "individual-investor",
  MarketProfessional = "market-professional",
}

export enum UserRoleEnum {
  Admin = "Admin",
  User = "User",
}

export enum MunicipalityTypeEnum {
  State = "State",
  City = "City",
  County = "County",
  SchoolDistrict = "School District",
  SewerAndWaterDistrict = "Sewer and Water District",
  TransitAuthority = "Transit Authority",
  Airport = "Airport",
  HousingAuthority = "Housing Authority",
  HousingFinanceAgency = "Housing Finance Agency",
  EconomicIndustrialDevelopmentAuthority = "Economic/Industrial Development Authority",
  PublicUtility = "Public Utility",
  PortAuthority = "Port Authority",
  SpecialTaxingDistrict = "Special Taxing District",
  CollegeUniversity = "College/University",
  GreenBank = "Green Bank",
}

export enum AccountStatusEnum {
  New = "New",
  PendingApproval = "Pending Approval",
  Active = "Active",
  Suspended = "Suspended",
  Error = "Error",
  Deactivated = "Deactivated",
}

export enum MunicipalityAttachmentTypeEnum {
  BannerImage = "BannerImage",
  EnablementDoc = "EnablementDocument",
  MarketNewsImage = "MarketNewsImage",
}

export enum CreditRatingValuesSnPEnum {
  Unrated = "Unrated",
  AAA = "AAA",
  AAPlus = "AA+",
  AA = "AA",
  AAminus = "AA-",
  APlus = "A+",
  A = "A",
  AMinus = "A-",
  BBBPlus = "BBB+",
  BBB = "BBB",
  BBBMinus = "BBB-",
  BBPlus = "BB+",
  BB = "BB",
  BBMinus = "BB-",
  BPlus = "B+",
  B = "B",
  BMinus = "B-",
  CCCPlus = "CCC+",
  CCC = "CCC",
  CCCMinus = "CCC-",
  CC = "CC",
  C = "C",
  D = "D",
}

export enum CreditRatingValuesMoodysEnum {
  Unrated = "Unrated",
  Aaa = "Aaa",
  Aa1 = "Aa1",
  Aa2 = "Aa2",
  Aa3 = "Aa3",
  A1 = "A1",
  A2 = "A2",
  A3 = "A3",
  Baa1 = "Baa1",
  Baa2 = "Baa2",
  Baa3 = "Baa3",
  Ba1 = "Ba1",
  Ba2 = "Ba2",
  Ba3 = "Ba3",
  B1 = "B1",
  B2 = "B2",
  B3 = "B3",
  Caa1 = "Caa1",
  Caa2 = "Caa2",
  Caa3 = "Caa3",
  Ca = "Ca",
  C = "C",
}

export enum CreditRatingValuesFitchEnum {
  Unrated = "Unrated",
  AAA = "AAA",
  AAPlus = "AA+",
  AA = "AA",
  AAminus = "AA-",
  APlus = "A+",
  A = "A",
  AMinus = "A-",
  BBBPlus = "BBB+",
  BBB = "BBB",
  BBBMinus = "BBB-",
  BBPlus = "BB+",
  BB = "BB",
  BBMinus = "BB-",
  BPlus = "B+",
  B = "B",
  BMinus = "B-",
  CCCPlus = "CCC+",
  CCC = "CCC",
  CCCMinus = "CCC-",
  CC = "CC",
  C = "C",
  RD = "RD",
  D = "D",
}

export enum CreditRatingValuesKrollEnum {
  Unrated = "Unrated",
  AAA = "AAA",
  AAPlus = "AA+",
  AA = "AA",
  AAminus = "AA-",
  APlus = "A+",
  A = "A",
  AMinus = "A-",
  BBBPlus = "BBB+",
  BBB = "BBB",
  BBBMinus = "BBB-",
  BBPlus = "BB+",
  BB = "BB",
  BBMinus = "BB-",
  BPlus = "B+",
  B = "B",
  BMinus = "B-",
  CCCPlus = "CCC+",
  CCC = "CCC",
  CCCMinus = "CCC-",
  CC = "CC",
  C = "C",
  D = "D",
}

export enum InvestmentExperienceEnum {
  Beginner = "Beginner",
  Intermediate = "Intermediate",
  Advanced = "Advanced",
  Expert = "Expert",
  Professional = "Professional",
}

export enum AuctionTaxStatusEnum {
  Taxable = "Taxable",
  TaxExempt = "Tax Exempt",
  AltMinTaxAmt = "Alternative Minimum Tax Amount",
}

export enum AuctionRedemptionProvisionsEnum {
  Callable = "Callable",
  NonCallable = "Non-Callable",
}

export enum AuctionOfferingTypesEnum {
  Public = "Public",
  Private = "Private",
}

export enum AuctionSecurityTypesEnum {
  GeneralObligation = "General Obligation",
  Revenue = "Revenue",
}

export enum AuctionRepaymentFrequencyEnum {
  Monthly = "Monthly",
  SemiAnnually = "Semi-Annual",
  Annually = "Annual",
}

export enum AuctionProfessionalTypeEnum {
  BondCounsel = "Bond Counsel",
  AuctionTrustee = "Auction Trustee",
  MunicipalAdvisor = "Municipal Advisor",
}

export enum AuctionStatusEnum {
  Draft = "Draft",
  PendingReview = "Pending Review",
  Rejected = "Rejected",
  Approved = "Approved",
  Scheduled = "Scheduled",
  Live = "Live",
  Ended = "Ended",
}

export enum LevelOfInterestEnum {
  NoInterest = "No Interest",
  SlightlyInterested = "Slightly Interested",
  Interested = "Interested",
  VeryInterested = "Very Interested",
  IntendToBid = "Intend To Bid",
}

export enum StatsTimeFrameEnum {
  Daily = "Daily",
  Weekly = "Weekly",
  Monthly = "Monthly",
}

export enum MunicipalityViewOptionsEnum {
  ViewAllMunivestorDeals = "View all Munivestor deals",
  ViewOnlyMyMunicipality = "View only my Municipality",
}
