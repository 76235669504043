import type { BadgeProps } from "@mantine/core";
import { Badge } from "@mantine/core";
import { AccountStatusEnum } from "@munivestor/types";
import { z } from "zod";

export interface AccountStatusBadgeProps extends BadgeProps {
  status?: AccountStatusEnum | string;
}

export function AccountStatusBadge(props: AccountStatusBadgeProps) {
  const { status, ...rest } = props;
  if (!status) {
    return null;
  }
  const parsedStatus = z.nativeEnum(AccountStatusEnum).safeParse(status);
  if (!parsedStatus.success) {
    return null;
  }
  const { color, label } = BadgeMap[parsedStatus.data];
  return (
    <Badge color={color} variant="outline" {...rest}>
      {label}
    </Badge>
  );
}

const BadgeMap: Record<AccountStatusEnum, { color: string; label: string }> = {
  [AccountStatusEnum.Active]: {
    color: "green",
    label: "Active",
  },
  [AccountStatusEnum.Deactivated]: {
    color: "gray",
    label: "Disabled",
  },
  [AccountStatusEnum.Error]: {
    color: "red",
    label: "Error",
  },
  [AccountStatusEnum.New]: {
    color: "blue",
    label: "New",
  },
  [AccountStatusEnum.PendingApproval]: {
    color: "orange.4",
    label: "Pending",
  },
  [AccountStatusEnum.Suspended]: {
    color: "yellow",
    label: "Suspended",
  },
};
