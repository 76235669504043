import type { ApiFetcher } from "@ts-rest/core";
import type { AxiosError, AxiosInstance, AxiosResponse, Method } from "axios";
import { isAxiosError } from "axios";

export function customApiFactory(axiosInst: AxiosInstance) {
  const apiFetcher: ApiFetcher = async ({
    path,
    method,
    headers,
    body,
    contentType,
  }) => {
    if (contentType === "multipart/form-data") {
      headers = {
        ...headers,
        "Content-Type": "multipart/form-data",
      };
    }
    try {
      const result = await axiosInst.request({
        method: method as Method,
        url: path,
        headers,
        data: body,
      });
      const respHeaders = new Headers();
      for (const [key, value] of Object.entries(result.headers)) {
        respHeaders.set(key, value as string);
      }

      return {
        status: result.status,
        body: result.data,
        headers: respHeaders,
      };
    } catch (e: Error | AxiosError | any) {
      if (isAxiosError(e)) {
        const error = e as AxiosError;
        const response = error.response as AxiosResponse;
        const respHeaders = new Headers();
        for (const [key, value] of Object.entries(response.headers)) {
          respHeaders.set(key, value as string);
        }
        return {
          status: response.status,
          body: response.data,
          headers: respHeaders,
        };
      }
      throw e;
    }
  };
  return apiFetcher;
}
