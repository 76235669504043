import type { TextProps } from "@mantine/core";
import { Text } from "@mantine/core";
import type { FontProps, TypographyLevel } from "./types.js";

export interface BodyTextProps extends TextProps {
  bold?: boolean;
  children: React.ReactNode;
  level?: TypographyLevel;
}

const getFontProps = (level: TypographyLevel): FontProps => {
  switch (level) {
    case 1:
      return {
        size: "18px",
        lh: "28px",
      };
    case 2:
      return {
        size: "16px",
        lh: "24px",
      };
    case 3:
      return {
        size: "14px",
        lh: "20px",
      };

    default:
      return {
        size: "18px",
        lh: "28px",
      };
  }
};

export function BodyText(props: BodyTextProps) {
  const { children, bold = false, level = 2, ...rest } = props;

  const fontWeight = bold ? 700 : 400;
  const fontProps = getFontProps(level);

  return (
    <Text fw={fontWeight} {...fontProps} {...rest}>
      {children}
    </Text>
  );
}
