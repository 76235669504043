import {
  Button as MButton,
  type ButtonProps as MButtonProps,
} from "@mantine/core";
import { Link } from "react-router-dom";
import classes from "./Buttons.module.css";

export type ButtonProps = MButtonProps &
  React.ComponentPropsWithoutRef<"button"> & {
    children: React.ReactNode;
    colorTheme?: "primary" | "secondary";
  };

export function Button(props: ButtonProps) {
  const { children, colorTheme = "primary", variant, ...rest } = props;

  return (
    <MButton
      className={classes.btn}
      color={colorTheme === "primary" ? undefined : "secondary"}
      variant={variant}
      {...rest}
    >
      {children}
    </MButton>
  );
}

Button.defaultProps = {
  colorTheme: "primary",
};

export type LinkButtonProps = MButtonProps &
  React.ComponentPropsWithoutRef<"a"> & {
    children: React.ReactNode;
    href?: string;
    isExternalLink?: boolean;
  };

export function LinkButton(props: LinkButtonProps) {
  const { children, href, isExternalLink = false, variant, ...rest } = props;

  const isTextLink = variant === "transparent";

  return isExternalLink ? (
    <MButton
      className={classes.btn}
      classNames={isTextLink ? { root: classes.linkButton } : undefined}
      component="a"
      color="cyan.7"
      href={href}
      target="_blank"
      variant={variant}
      {...rest}
    >
      {children}
    </MButton>
  ) : (
    <MButton
      className={classes.btn}
      classNames={isTextLink ? { root: classes.linkButton } : undefined}
      component={Link}
      color="cyan.7"
      to={href ?? ""}
      variant={variant}
      {...rest}
    >
      {children}
    </MButton>
  );
}
