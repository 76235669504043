import { z } from "zod";

export const UserInfoSchema = z.object({
  userId: z.number(),
  uuid: z.string(),
  email: z.string(),
  name: z.string(),
  registeredByPassword: z.boolean(),
});

export type UserInfoType = z.infer<typeof UserInfoSchema>;
