import { AdminRouter } from "@munivestor/contracts";
import { initQueryClient } from "@ts-rest/react-query";
import { customApiFactory } from "./apiFactory.js";
import { axiosAuthedInstance } from "./axiosAuthedInstances.js";

export const AdminApiClient = initQueryClient(AdminRouter, {
  baseUrl: "",
  baseHeaders: {},
  api: customApiFactory(axiosAuthedInstance),
});
