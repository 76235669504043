import { AccountStatusEnum } from "@munivestor/types";
import { pgTable, text } from "drizzle-orm/pg-core";
import { createCommonCols } from "../helpers/common-cols.js";
import { accountStatusPgEnum } from "../helpers/enums.js";

export const MarketProfOrg = pgTable("market_prof_org", {
  ...createCommonCols("market_prof_org"),
  name: text("name").notNull(),
  description: text("summary"),
  website: text("website"),
  status: accountStatusPgEnum("status")
    .notNull()
    .default(AccountStatusEnum.New),
});

export type MarketProfOrgEntityType = typeof MarketProfOrg.$inferSelect;
