import { AdminAuctionRouter } from "./admin/auction.contract.js";
import {
  AdminAuthPublicRouter,
  AdminAuthRouter,
} from "./admin/auth.contract.js";
import { AdminUserManagementRouter } from "./admin/onboarding.contract.js";
import { AuctionRouter } from "./auction.contract.js";
import { AuthPublicRouter, AuthRouter } from "./auth.contract.js";
import { adminApiContract, apiContract } from "./contract.js";
import { InvestorRouter } from "./investor.contract.js";
import { InviteOpenRouter, InviteRouter } from "./invite.contract.js";
import { MarketProfessionalRouter } from "./market-professional.contract.js";
import { MunicipalityRouter } from "./municipality.contract.js";
import { NotificationRouter } from "./notifications.contract.js";
import { OnboardingInstitutionalInvestorRouter } from "./onboarding-institutional-investor.contract.js";
import { OnboardingInvestorRouter } from "./onboarding-investor.contract.js";
import { OnboardingMarketProfRouter } from "./onboarding-market-prof.contract.js";
import { OnboardingMuniRouter } from "./onboarding-muni.contract.js";
import { SocialRouter } from "./social.contract.js";

export { AuthRouter, AuthPublicRouter } from "./auth.contract.js";
export {
  AdminAuthPublicRouter,
  AdminAuthRouter,
} from "./admin/auth.contract.js";

// ---- App routers ----
export const AppRouter = apiContract.router(
  {
    auth: AuthRouter,
    auction: AuctionRouter,
    investor: InvestorRouter,
    invite: InviteRouter,
    marketProfessional: MarketProfessionalRouter,
    municipality: MunicipalityRouter,
    notifications: NotificationRouter,
    onboardingInvestor: OnboardingInvestorRouter,
    onboardingInstitutionalInvestor: OnboardingInstitutionalInvestorRouter,
    onboardingMarketProf: OnboardingMarketProfRouter,
    onboardingMuni: OnboardingMuniRouter,
    social: SocialRouter,
  },
  {
    strictStatusCodes: true,
  },
);

export const AppPublicRouter = apiContract.router(
  {
    auth: AuthPublicRouter,
    invite: InviteOpenRouter,
  },
  {
    strictStatusCodes: true,
  },
);

// ---- Admin app routers ----

export const AdminRouter = adminApiContract.router(
  {
    auction: AdminAuctionRouter,
    auth: AdminAuthRouter,
    onboarding: AdminUserManagementRouter,
  },
  {
    strictStatusCodes: true,
  },
);

export const AdminPublicRouter = adminApiContract.router(
  {
    auth: AdminAuthPublicRouter,
  },
  {
    strictStatusCodes: true,
  },
);

// ---- Public Api app routers ----
export { PublicApiRouter } from "./public.contract.js";
