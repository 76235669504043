import { z } from "zod";
import {
  FirebaseConfigSchema,
  MessageErrorSchema,
  AdminUserMetadataSchema,
  SignupAdminRequestSchema,
} from "../../schemas/index.js";
import { StatusCodes } from "../../utils/http-status-codes.js";
import { apiContract } from "../contract.js";

export const AdminAuthRouter = apiContract.router(
  {
    // ---- Get current user metadata ----
    getUserMetadata: {
      method: "GET",
      path: "metadata",
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: AdminUserMetadataSchema,
        }),
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Fetch admin user data",
    },
  },
  // ---- Options ----
  {
    pathPrefix: "/api/auth/",
    strictStatusCodes: true,
  },
);

export const AdminAuthPublicRouter = apiContract.router(
  {
    // ---- Get firebase config ----
    getFirebaseConfig: {
      method: "GET",
      path: "firebase-config",
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: FirebaseConfigSchema,
        }),
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Get firebase config",
    },

    // ---- Sign up admin ----
    signupAdmin: {
      method: "POST",
      path: "signup",
      body: SignupAdminRequestSchema,
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: z
            .object({
              userId: z.number(),
            })
            .optional(),
        }),
        [StatusCodes.BadRequest]: MessageErrorSchema,
        [StatusCodes.Unauthorized]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Sign up admin",
    },
  },
  // ---- Options ----
  {
    pathPrefix: "/api/auth/",
    strictStatusCodes: true,
  },
);
