import { z } from "zod";
import {
  IndieInvestorOnboardingFormSchema,
  IndieInvestorOnboardingResponseSchema,
  MessageErrorSchema,
} from "../schemas/index.js";
import { StatusCodes } from "../utils/http-status-codes.js";
import { apiContract } from "./contract.js";

export const OnboardingInvestorRouter = apiContract.router(
  {
    // ---- Get investor profile ----
    getInvestor: {
      method: "GET",
      path: "",
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: IndieInvestorOnboardingResponseSchema,
        }),
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.BadRequest]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Fetch investor onboarding info",
    },

    // ---- Update investor overview ----
    updateIndieInvestor: {
      method: "PUT",
      path: "",
      body: IndieInvestorOnboardingFormSchema,
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
        }),
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Update indie investor overview",
    },

    // ---- Request onboarding approval  ----
    requestIndieInvestorApproval: {
      method: "POST",
      path: "request-approval",
      body: z.unknown(),
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
        }),
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.PreconditionFailed]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Request approval for indie investor onboarding",
    },
  },

  // ---- Options ----
  {
    pathPrefix: "/api/onboarding/indie-investor/",
    strictStatusCodes: true,
  },
);
