import { NotificationHistory } from "@munivestor/models";
import { createSelectSchema } from "drizzle-zod";
import { z } from "zod";

// ---- Notification event types ----
export enum NotificationTypeEnum {
  InviteeJoined = "InviteeJoined",
  AuctionApproved = "AuctionApproved",
  AuctionRejected = "AuctionRejected",
  AccountApproved = "AccountApproved",
  AccountRejected = "AccountRejected",
  AuctionLive = "AuctionLive",
  AuctionClosed = "AuctionClosed",
  AuctionClosureReminder = "AuctionClosureReminder",
  AuctionReadyToAcceptFeedback = "AuctionReadyToAcceptFeedback",
  Test = "Test",
  SSEType = "System.SSEType", // ⚠️ this is used for all server-sent events
}

// ---- Notification event schema ----
export const NotificationEventSchema = z.object({
  id: z.string(),
  data: z.any(),
  title: z.string().optional(),
  message: z.string().optional(),
  silent: z.boolean().optional(),
  type: z.nativeEnum(NotificationTypeEnum),
});

export type NotificationEventType = z.infer<typeof NotificationEventSchema>;

// ---- Notification history schema ----
// used to store notification history in browser storage
export const PushNotificationPreferencesSchema = z.object({
  userUuid: z.string(),
  allowed: z.boolean(),
  lastUpdated: z.coerce.date(),
  token: z.string(),
});

export type PushNotificationPreferencesType = z.infer<
  typeof PushNotificationPreferencesSchema
>;

// ---- Notification history schema ----
export const NotificationHistorySchema = createSelectSchema(
  NotificationHistory,
).extend({
  data: z.any(),
  user: z.object({
    uuid: z.string(),
    email: z.string(),
  }),
});

export type NotificationHistoryType = z.infer<typeof NotificationHistorySchema>;
