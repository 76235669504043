import { InvestorOrg, User } from "@munivestor/models";
import { USStates } from "@munivestor/types";
import { createSelectSchema } from "drizzle-zod";
import { z } from "zod";

// ---- Institutional Investor Overview ----

export const InvestorOrgSchema = createSelectSchema(InvestorOrg).pick({
  id: true,
  status: true,
  name: true,
  website: true,
  city: true,
  state: true,
  investmentExperience: true,
  targetIssuers: true,
  isInterestedInChat: true,
  isSeekingFeaturedInvestorStatus: true,
});

export type InvestorOrgType = z.infer<typeof InvestorOrgSchema>;

export const InstitutionalInvestorOnboardingResponseSchema = z
  .object({
    org: InvestorOrgSchema,
  })
  .merge(
    createSelectSchema(User).pick({
      email: true,
      firstName: true,
      lastName: true,
      address: true,
      businessDesignation: true,
      city: true,
      phone: true,
      phoneExtension: true,
      state: true,
      zipCode: true,
    }),
  );

export type InstitutionalInvestorOnboardingResponseType = z.infer<
  typeof InstitutionalInvestorOnboardingResponseSchema
>;

// ---- About Institution ----
export const AboutInstitutionFormSchema = z.strictObject({
  name: z.string().min(1, { message: "Name is required" }),
  website: z.string().optional(),
  city: z.string().min(1, { message: "City is required" }),
  state: z
    .string()
    .min(1, { message: "State is required" })
    .refine((val) => USStates.find((state) => state.id === val), {
      message: "Invalid state",
    }),
  investmentExperience: z.string().optional(),
  targetIssuers: z.array(z.string()).optional(),
  isInterestedInChat: z.boolean().optional(),
  isSeekingFeaturedInvestorStatus: z.boolean().optional(),
});

export type AboutInstitutionFormType = z.infer<
  typeof AboutInstitutionFormSchema
>;

// ---- About You ----
export const AboutInvestorFormSchema = z.strictObject({
  firstName: z.string().min(1, { message: "First name is required" }),
  lastName: z.string().min(1, { message: "Last name is required" }),
  businessDesignation: z.string().optional(),
  address: z.string().min(1, { message: "Address is required" }),
  city: z.string().min(1, { message: "City is required" }),
  state: z
    .string()
    .min(1, { message: "State is required" })
    .refine((val) => USStates.find((state) => state.id === val), {
      message: "Invalid state",
    }),
  zipCode: z.string().min(1, { message: "Zip code is required" }),
  phone: z.string().optional(),
  phoneExtension: z.string().optional(),
});

export type AboutInvestorFormType = z.infer<typeof AboutInvestorFormSchema>;

//  ---- Institution Investor List ----
export const InstInvestorListSchema = createSelectSchema(InvestorOrg).pick({
  id: true,
  status: true,
  name: true,
  investmentExperience: true,
  city: true,
  state: true,
});

export type InstInvestorListType = z.infer<typeof InstInvestorListSchema>;

// ---- Indie Investor Detail ----
export const InstInvestorDetailSchema = z.strictObject({
  overview: createSelectSchema(InvestorOrg),
  user: createSelectSchema(User),
});

export type InstInvestorDetailType = z.infer<typeof InstInvestorDetailSchema>;
