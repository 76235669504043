import { integer, pgTable, text } from "drizzle-orm/pg-core";
import { createCommonCols } from "../helpers/common-cols.js";
import { User } from "./user.entity.js";

export const PushToken = pgTable("push_token", {
  ...createCommonCols("push_token"),
  token: text("token").notNull().unique(),
  userId: integer("userId").references(() => User.id),
});

export type PushTokenEntityType = typeof PushToken.$inferSelect;
