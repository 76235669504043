import { boolean, integer, jsonb, pgTable, text } from "drizzle-orm/pg-core";
import { createCommonCols } from "../helpers/common-cols.js";
import { User } from "./user.entity.js";

export const NotificationHistory = pgTable("notif_history", {
  ...createCommonCols("notif_history"),
  msgId: text("msgId").notNull(),
  type: text("type").notNull(),
  title: text("title").notNull(),
  body: text("body"),
  data: jsonb("data"),
  isRead: boolean("isRead").notNull().default(false),
  userId: integer("userId")
    .references(() => User.id)
    .notNull(),
});

export type NotificationHistoryEntityType =
  typeof NotificationHistory.$inferSelect;
