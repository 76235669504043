import { MuniCreditRating, MuniPersonnel } from "@munivestor/models";
import { createSelectSchema } from "drizzle-zod";
import { z } from "zod";
import { MessageErrorSchema } from "../schemas/http-response.schema.js";
import {
  MuniCreditRatingFormSchema,
  MuniPersonnelFormSchema,
  MunicipalityBannerUploadSchema,
  MunicipalityDocsRequestSchema,
  MunicipalityFinEconProfileSchema,
  MunicipalityOnboardingInfoResponseSchema,
  MunicipalityOverviewFormSchema,
} from "../schemas/municipality.schema.js";
import { StatusCodes } from "../utils/http-status-codes.js";
import { apiContract } from "./contract.js";

export const OnboardingMuniRouter = apiContract.router(
  {
    // ---- Get municipality profile ----
    getMunicipality: {
      method: "GET",
      path: "",
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: MunicipalityOnboardingInfoResponseSchema,
        }),
        [StatusCodes.Unauthorized]: MessageErrorSchema,
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Fetch municipality onboarding info",
    },

    // ---- Update municipality overview ----
    updateMunicipalityOverview: {
      method: "PUT",
      path: "overview",
      body: MunicipalityOverviewFormSchema,
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
        }),
        [StatusCodes.BadRequest]: MessageErrorSchema,
        [StatusCodes.Unauthorized]: MessageErrorSchema,
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Update municipality overview",
    },

    // ---- Upload municipality banner image ----
    uploadMuniBanner: {
      method: "POST",
      path: "banner-image",
      body: MunicipalityBannerUploadSchema,
      contentType: "multipart/form-data",
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
        }),
        [StatusCodes.BadRequest]: MessageErrorSchema,
        [StatusCodes.Unauthorized]: MessageErrorSchema,
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Upload municipality banner image",
    },

    // ---- Delete municipality banner image ----
    removeMuniBanner: {
      method: "DELETE",
      path: "banner-image",
      body: z.unknown(),
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
        }),
        [StatusCodes.BadRequest]: MessageErrorSchema,
        [StatusCodes.Unauthorized]: MessageErrorSchema,
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Delete municipality banner image",
    },

    // --- Update + Upload municipality docs and links ---
    updateMuniDocs: {
      method: "POST",
      path: "docs",
      contentType: "multipart/form-data",
      body: MunicipalityDocsRequestSchema,
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
        }),
        [StatusCodes.BadRequest]: MessageErrorSchema,
        [StatusCodes.Unauthorized]: MessageErrorSchema,
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Update municipality emmaIssuerUrl and upload enablement docs",
    },

    // --- Delete municipality docs ---
    deleteMuniDoc: {
      method: "DELETE",
      path: "docs/:id",
      pathParams: z.object({
        id: z.coerce.number(),
      }),
      body: MunicipalityDocsRequestSchema,
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
        }),
        [StatusCodes.BadRequest]: MessageErrorSchema,
        [StatusCodes.Unauthorized]: MessageErrorSchema,
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Municipality enablement doc deleted",
    },

    // ---- Update municipality financial and economic profile ----
    updateMunicipalityFinEconProfile: {
      method: "PUT",
      path: "fin-econ-profile",
      body: MunicipalityFinEconProfileSchema,
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
        }),
        [StatusCodes.BadRequest]: MessageErrorSchema,
        [StatusCodes.Unauthorized]: MessageErrorSchema,
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Update municipality financial and economic profile",
    },

    // ---- Add municipality personnel ----
    addMunicipalityPersonnel: {
      method: "POST",
      path: "personnel",
      body: MuniPersonnelFormSchema,
      responses: {
        [StatusCodes.Ok]: z.object({
          data: createSelectSchema(MuniPersonnel),
          message: z.string(),
        }),
        [StatusCodes.Unauthorized]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Add Municipality personnel",
    },

    // ---- Update municipality personnel ----
    updateMunicipalityPersonnel: {
      method: "PUT",
      path: "personnel/:id",
      pathParams: z.object({
        id: z.coerce.number(),
      }),
      body: MuniPersonnelFormSchema,
      responses: {
        [StatusCodes.Ok]: z.object({
          data: createSelectSchema(MuniPersonnel),
          message: z.string(),
        }),
        [StatusCodes.Unauthorized]: MessageErrorSchema,
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Update Municipality personnel",
    },

    // ---- Delete municipality personnel ----
    deleteMunicipalityPersonnel: {
      method: "DELETE",
      path: "personnel/:id",
      pathParams: z.object({
        id: z.coerce.number(),
      }),
      body: z.unknown(),
      responses: {
        [StatusCodes.Ok]: z.object({
          data: createSelectSchema(MuniPersonnel),
          message: z.string(),
        }),
        [StatusCodes.Unauthorized]: MessageErrorSchema,
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Delete Municipality personnel update",
    },

    // ---- Add municipality credit rating ----
    addMunicipalityCreditRating: {
      method: "POST",
      path: "credit-rating",
      body: MuniCreditRatingFormSchema,
      responses: {
        [StatusCodes.Ok]: z.object({
          data: createSelectSchema(MuniCreditRating),
          message: z.string(),
        }),
        [StatusCodes.BadRequest]: MessageErrorSchema,
        [StatusCodes.Unauthorized]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Add Municipality credit rating",
    },

    // ---- Update municipality credit rating ----
    updateMunicipalityCreditRating: {
      method: "PUT",
      path: "credit-rating/:id",
      pathParams: z.object({
        id: z.coerce.number(),
      }),
      body: MuniCreditRatingFormSchema,
      responses: {
        [StatusCodes.Ok]: z.object({
          data: createSelectSchema(MuniCreditRating),
          message: z.string(),
        }),
        [StatusCodes.Unauthorized]: MessageErrorSchema,
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Update Municipality credit rating",
    },

    // ---- Delete municipality credit rating ----
    deleteMunicipalityCreditRating: {
      method: "DELETE",
      path: "credit-rating/:id",
      pathParams: z.object({
        id: z.coerce.number(),
      }),
      body: z.unknown(),
      responses: {
        [StatusCodes.Ok]: z.object({
          data: createSelectSchema(MuniCreditRating),
          message: z.string(),
        }),
        [StatusCodes.Unauthorized]: MessageErrorSchema,
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Delete Municipality credit rating",
    },

    // ---- Request approval for municipality onboarding ----
    requestMunicipalityApproval: {
      method: "POST",
      path: "request-approval",
      body: z.unknown(),
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
        }),
        [StatusCodes.Unauthorized]: MessageErrorSchema,
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Request approval for municipality onboarding",
    },
  },

  // ---- Options ----
  {
    pathPrefix: "/api/onboarding/municipality/",
    strictStatusCodes: true,
  },
);
