import {
  AccountStatusEnum,
  AuctionOfferingTypesEnum,
  AuctionProfessionalTypeEnum,
  AuctionRedemptionProvisionsEnum,
  AuctionRepaymentFrequencyEnum,
  AuctionSecurityTypesEnum,
  AuctionStatusEnum,
  AuctionTaxStatusEnum,
  CreditRatingValuesFitchEnum,
  CreditRatingValuesKrollEnum,
  CreditRatingValuesMoodysEnum,
  CreditRatingValuesSnPEnum,
  LevelOfInterestEnum,
  MunicipalityAttachmentTypeEnum,
  MunicipalityTypeEnum,
  UserRoleEnum,
  UserTypeEnum,
} from "@munivestor/types";
import { pgEnum } from "drizzle-orm/pg-core";

const createPgEnum = (name: string, nativeEnum: Record<string, string>) =>
  pgEnum(
    name,
    Object.values(nativeEnum) as unknown as readonly [string, ...Array<string>],
  );

export const userRolesPgEnum = createPgEnum("user_role", UserRoleEnum);

export const userTypesPgEnum = createPgEnum("user_type", UserTypeEnum);

export const accountStatusPgEnum = createPgEnum(
  "account_status",
  AccountStatusEnum,
);

export const muniTypesPgEnum = createPgEnum(
  "municipality_type",
  MunicipalityTypeEnum,
);

export const muniAttachmentTypesPgEnum = createPgEnum(
  "municipality_attachment_type",
  MunicipalityAttachmentTypeEnum,
);

export const creditRatingValuesFitchPgEnum = createPgEnum(
  "credit_rating_values_fitch",
  CreditRatingValuesFitchEnum,
);

export const creditRatingValuesMoodysPgEnum = createPgEnum(
  "credit_rating_values_moodys",
  CreditRatingValuesMoodysEnum,
);

export const creditRatingValuesSnPPgEnum = createPgEnum(
  "credit_rating_values_snp",
  CreditRatingValuesSnPEnum,
);

export const creditRatingValuesKrollPgEnum = createPgEnum(
  "credit_rating_values_kroll",
  CreditRatingValuesKrollEnum,
);

export const auctionSecurityTypesPgEnum = createPgEnum(
  "auction_security_type",
  AuctionSecurityTypesEnum,
);

export const auctionOfferingTypesPgEnum = createPgEnum(
  "auction_offering_type",
  AuctionOfferingTypesEnum,
);

export const auctionTaxStatusTypesPgEnum = createPgEnum(
  "auction_tax_status_type",
  AuctionTaxStatusEnum,
);

export const auctionRedemptionProvisionsPgEnum = createPgEnum(
  "auction_redemption_provisions",
  AuctionRedemptionProvisionsEnum,
);

export const auctionRepaymentFreqPgEnum = createPgEnum(
  "auction_repayment_freq",
  AuctionRepaymentFrequencyEnum,
);

export const auctionProfTypePgEnum = createPgEnum(
  "auction_prof_type",
  AuctionProfessionalTypeEnum,
);

export const auctionStatusPgEnum = createPgEnum(
  "auction_status",
  AuctionStatusEnum,
);

export const levelOfInterestPgEnum = createPgEnum(
  "level_of_interest",
  LevelOfInterestEnum,
);
