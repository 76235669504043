import type { TitleOrder, TitleProps } from "@mantine/core";
import { Title } from "@mantine/core";
import type { TypographyLevel } from "./types.js";

interface FontProps {
  size: string;
  lh: string;
  fw: number;
}

export interface SubtitleProps extends TitleProps {
  children: React.ReactNode;
  level: TypographyLevel;
}

const getFontProps = (level: TypographyLevel): FontProps => {
  switch (level) {
    case 1:
      return {
        size: "28px",
        fw: 700,
        lh: "32px",
      };
    case 2:
      return {
        size: "20px",
        fw: 700,
        lh: "32px",
      };
    case 3:
      return {
        size: "20px",
        fw: 500,
        lh: "32px",
      };

    default:
      return {
        size: "28px",
        fw: 700,
        lh: "32px",
      };
  }
};

export function Subtitle(props: SubtitleProps) {
  const { children, level, ...rest } = props;

  const fontProps = getFontProps(level);

  return (
    <Title order={(level + 3) as TitleOrder} {...fontProps} {...rest}>
      {children}
    </Title>
  );
}
