import { integer, pgTable } from "drizzle-orm/pg-core";
import { createCommonCols } from "../helpers/common-cols.js";
import { auctionProfTypePgEnum } from "../helpers/enums.js";
import { Auction } from "./auction.entity.js";
import { User } from "./user.entity.js";

export const AuctionProfessional = pgTable("auction_professional", {
  ...createCommonCols("auction_professional"),
  auctionId: integer("auctionId")
    .notNull()
    .references(() => Auction.id),
  marketProfId: integer("marketProfId")
    .notNull()
    .references(() => User.id),
  type: auctionProfTypePgEnum("type").notNull(),
});

export type AuctionProfessionalEntityType =
  typeof AuctionProfessional.$inferSelect;
