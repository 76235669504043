import { z } from "zod";
import {
  MessageErrorSchema,
  ResponseMessageSchema,
} from "../schemas/http-response.schema.js";
import {
  CreateMarketNewsSchema,
  MarketNewsSchema,
  MunicipalityInfoForDashboardSchema,
  MunicipalityStatsRequestSchema,
  MunicipalityStatsResponseSchema,
} from "../schemas/municipality.schema.js";
import { StatusCodes } from "../utils/http-status-codes.js";
import { apiContract } from "./contract.js";

export const MunicipalityRouter = apiContract.router(
  {
    // ---- Get municipality info ----
    muniInfo: {
      method: "GET",
      path: "info",
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: MunicipalityInfoForDashboardSchema,
        }),
        [StatusCodes.BadRequest]: MessageErrorSchema,
        [StatusCodes.Forbidden]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Get info of the current municipality",
    },

    // ---- Market News List ----
    marketNews: {
      method: "GET",
      path: "market-news",
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: z.array(MarketNewsSchema),
        }),
        [StatusCodes.BadRequest]: MessageErrorSchema,
        [StatusCodes.Forbidden]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
    },

    // ---- Add market news ----
    addMarketNews: {
      method: "POST",
      path: "market-news",
      body: CreateMarketNewsSchema,
      contentType: "multipart/form-data",
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: MarketNewsSchema,
        }),
        [StatusCodes.BadRequest]: MessageErrorSchema,
        [StatusCodes.Forbidden]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
    },

    // ---- Delete market news ----
    deleteMarketNews: {
      method: "DELETE",
      path: "market-news/:uuid",
      pathParams: z.object({
        uuid: z.string(),
      }),
      body: z.unknown(),
      responses: {
        [StatusCodes.Ok]: ResponseMessageSchema,
        [StatusCodes.BadRequest]: MessageErrorSchema,
        [StatusCodes.Forbidden]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
    },

    // ---- Get municipality stats----
    getMunicipalityStats: {
      method: "GET",
      path: "stats",
      query: MunicipalityStatsRequestSchema,
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: MunicipalityStatsResponseSchema,
        }),
        [StatusCodes.BadRequest]: MessageErrorSchema,
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.Forbidden]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
    },
  },

  // ---- Options ----
  {
    pathPrefix: "/api/municipality/",
    strictStatusCodes: true,
  },
);
