import { firebaseAuthService } from "@munivestor/ui/services";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { RouteMap } from "@constants/RouteMap.js";

export function useSignOut() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: async () => {
      await firebaseAuthService.signOut();
      queryClient.invalidateQueries();
      queryClient.resetQueries();

      // add a delay to let things settle
      setTimeout(() => {
        navigate(RouteMap.Public.home, { replace: true });
      }, 1000);
    },
  });
}
