import { z } from "zod";
import { MessageErrorSchema } from "../schemas/http-response.schema.js";
import { NotificationHistorySchema } from "../schemas/notifications.schema.js";
import { StatusCodes } from "../utils/http-status-codes.js";
import { apiContract } from "./contract.js";

export const NotificationRouter = apiContract.router(
  {
    // ---- Register device token for notifications ----
    registerForNotifications: {
      method: "POST",
      path: "register",
      body: z.object({
        token: z.string(),
      }),
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
        }),
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Register device token for notifications",
    },

    // ---- Test push notification ----
    testPush: {
      method: "POST",
      path: "push-test",
      body: z.object({
        uuid: z.string(),
      }),
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
        }),
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Test push notification",
    },

    // ---- Test server-sent event ----
    testSse: {
      method: "POST",
      path: "sse-test",
      body: z.object({
        uuid: z.string(),
      }),
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
        }),
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Test push notification",
    },

    // ---- Test all notifications ----
    testAll: {
      method: "POST",
      path: "test",
      body: z.object({
        uuid: z.string(),
      }),
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
        }),
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Test all notifications",
    },

    // ---- Get notification history ----
    getHistory: {
      method: "GET",
      path: "history",
      responses: {
        [StatusCodes.Ok]: z.object({
          data: z.array(NotificationHistorySchema),
        }),
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Get notification history",
    },

    // ---- Mark notification as read ----
    markAsRead: {
      method: "PATCH",
      path: ":id/mark-read",
      pathParams: z.object({
        id: z.coerce.number(),
      }),
      body: z.unknown(),
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
        }),
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Mark notification as read",
    },

    // ---- Mark all notifications as read ----
    markAllAsRead: {
      method: "POST",
      path: "mark-all-read",
      body: z.unknown(),
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
        }),
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Mark all notifications as read",
    },
  },

  // ---- Options ----
  {
    pathPrefix: "/api/notifications/",
    strictStatusCodes: true,
  },
);
