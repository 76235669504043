import type {
  FirebaseConfigType,
  FirebaseSigninType,
  FirebaseSignupType,
} from "@munivestor/contracts";
import { FirebaseConfigSchema } from "@munivestor/contracts";
import type { FirebaseApp } from "firebase/app";
import { initializeApp } from "firebase/app";
import type { Auth, User as FirebaseUser } from "firebase/auth";
import {
  createUserWithEmailAndPassword,
  getAuth,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";
import type { Messaging } from "firebase/messaging";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import * as BrowserStorage from "../constants/index.js";
import { ApiPublicClient } from "./ApiClientPublic.js";

class FirebaseAppService {
  private app: FirebaseApp;

  private async fetchFirebaseConfig() {
    let config = this.loadFirebaseConfig();
    if (!config) {
      const response = await ApiPublicClient.auth.getFirebaseConfig.query();
      if (response.status !== 200) {
        throw new Error("Could not get firebase config");
      }
      config = response.body.data;
      if (!config) {
        throw new Error("Could not get firebase config");
      }
      this.saveFirebaseConfig(config);
    }
    return config;
  }

  private saveFirebaseConfig(config: FirebaseConfigType) {
    sessionStorage.setItem(
      BrowserStorage.FIREBASE_CONFIG,
      JSON.stringify(config),
    );
  }

  private loadFirebaseConfig(): FirebaseConfigType | null {
    const config = sessionStorage.getItem(BrowserStorage.FIREBASE_CONFIG);
    if (!config) {
      return null;
    }
    try {
      return FirebaseConfigSchema.parse(JSON.parse(config));
    } catch {
      return null;
    }
  }

  async getInstance(): Promise<FirebaseApp> {
    if (this.app) {
      return this.app;
    }
    const config = await this.fetchFirebaseConfig();
    if (!config) {
      throw new Error("Could not get firebase config");
    }
    this.app = initializeApp(config);
    return this.app;
  }
}

class FirebaseAuthService {
  private user: FirebaseUser | undefined;
  private auth: Auth;
  private appService: FirebaseAppService;

  constructor(appService: FirebaseAppService) {
    this.appService = appService;
  }

  private async initializeAuth() {
    const app = await this.appService.getInstance();
    this.auth = getAuth(app);

    this.auth.onAuthStateChanged((user) => {
      if (user) {
        this.user = user;
      } else {
        this.user = undefined;
      }
    });
  }

  private async getFirebaseUser(
    numRetries = 5,
  ): Promise<FirebaseUser | undefined> {
    await this.initializeAuth();
    // poll for `user` to be available
    if (!this.user && numRetries > 0) {
      await new Promise((resolve) => setTimeout(resolve, 500));
      return this.getFirebaseUser(numRetries - 1);
    }
    if (!this.user?.emailVerified) {
      this.user = undefined;
    }
    return this.user;
  }

  public async getIdToken(): Promise<string | null> {
    const user = await this.getFirebaseUser();
    if (!user) {
      return null;
    }
    return user.getIdToken();
  }

  async getFirebaseAuth() {
    if (this.auth) {
      return this.auth;
    }
    await this.initializeAuth();
    return this.auth;
  }

  async signup(params: FirebaseSignupType): Promise<FirebaseUser> {
    const { email, password } = params;
    try {
      const fbAuth = await this.getFirebaseAuth();
      const data = await createUserWithEmailAndPassword(
        fbAuth,
        email,
        password,
      );
      return data.user;
    } catch (error) {
      console.error("Error signing up", error);
      throw error;
    }
  }

  async sendAccountVerificationMail(user: FirebaseUser) {
    return sendEmailVerification(user);
  }

  async signIn(params: FirebaseSigninType): Promise<FirebaseUser> {
    const { email, password } = params;
    let user: FirebaseUser;
    try {
      const fbAuth = await this.getFirebaseAuth();
      const data = await signInWithEmailAndPassword(fbAuth, email, password);
      user = data.user;
    } catch (error) {
      throw error;
    }
    if (!user.emailVerified) {
      throw new Error("Email Not Verified");
    }
    return user;
  }

  async resendVerificationMail({
    email,
    password,
  }: FirebaseSigninType): Promise<void> {
    let user: FirebaseUser;
    try {
      const fbAuth = await this.getFirebaseAuth();
      const data = await signInWithEmailAndPassword(fbAuth, email, password);
      user = data.user;
    } catch (error) {
      throw error;
    }
    if (user.emailVerified) {
      throw new Error("Email already verified");
    }
    return sendEmailVerification(user);
  }

  async resetPassword({ email }: { email: string }): Promise<void> {
    try {
      const fbAuth = await this.getFirebaseAuth();
      await sendPasswordResetEmail(fbAuth, email);
    } catch (error) {
      throw error;
    }
  }

  async signOut() {
    const fbAuth = await this.getFirebaseAuth();
    await fbAuth.signOut();
  }
}

export class FirebaseMessagingService {
  private appService: FirebaseAppService;
  private messaging: Messaging;

  constructor(appService: FirebaseAppService) {
    this.appService = appService;
  }

  private async initializeMessaging() {
    if (this.messaging) {
      return;
    }
    const app = await this.appService.getInstance();
    this.messaging = getMessaging(app);
  }

  private async requestPermission(): Promise<boolean> {
    return Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        console.log("Notification permission granted.");
        return true;
      } else {
        console.log("Unable to get permission to notify.");
        return false;
      }
    });
  }

  hasPermission(): boolean {
    return Notification.permission === "granted";
  }

  async getToken({ vapidKey }: { vapidKey: string }): Promise<string> {
    if (!this.hasPermission()) {
      const permission = await this.requestPermission();
      if (!permission) {
        throw new Error("Permission denied");
      }
    }
    const swRegistration = await navigator.serviceWorker.register(
      "/firebase-messaging-sw.js",
    );
    await this.initializeMessaging();
    return getToken(this.messaging, {
      vapidKey,
      serviceWorkerRegistration: swRegistration,
    });
  }

  async registerOnMessageCallback(cb: (payload: any) => void) {
    await this.initializeMessaging();
    return onMessage(this.messaging, (payload) => {
      cb(payload);
    });
  }
}

const firebaseAppService = new FirebaseAppService();
export const firebaseAuthService = new FirebaseAuthService(firebaseAppService);
export const firebaseMessagingService = new FirebaseMessagingService(
  firebaseAppService,
);
