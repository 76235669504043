import {
  date,
  decimal,
  integer,
  pgTable,
  unique,
  text,
} from "drizzle-orm/pg-core";
import { createCommonCols } from "../helpers/common-cols.js";
import { Auction } from "./auction.entity.js";
import { User } from "./user.entity.js";

export const AuctionBid = pgTable(
  "auction_bid",
  {
    ...createCommonCols("auction_bid"),
    auctionId: integer("auctionId").references(() => Auction.id),
    bidId: text("bidId").notNull(), // groups investments under a single bid
    userId: integer("userId").references(() => User.id),
    investmentAmount: decimal("amount", {
      precision: 15,
      scale: 2,
    }).notNull(),
    maturityDate: date("maturityDate").notNull(),
  },
  (table) => ({
    uniq: unique().on(
      table.bidId,
      table.auctionId,
      table.userId,
      table.maturityDate,
    ),
  }),
);

export type AuctionBidEntityType = typeof AuctionBid.$inferSelect;
