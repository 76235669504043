import { lazy } from "react";

const MIN_DELAY = 250; // ms

// lazy load component with a minimum delay
export const lazyLoad = (importStatement: () => Promise<{ default: any }>) =>
  lazy(() =>
    Promise.all([
      importStatement(),
      new Promise((resolve) => setTimeout(resolve, MIN_DELAY)),
    ]).then(([moduleExports]) => moduleExports),
  );
