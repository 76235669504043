import { Box, Button, Flex, Modal } from "@mantine/core";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Subtitle } from "../../atoms/Typography/Subtitle.js";

export interface FormDialogProps {
  title?: string;
  children: React.ReactNode;
  buttonCancelColor?: string;
  buttonCancelText?: string;
  buttonSubmitColor?: string;
  buttonSubmitText?: string;
}

export interface FormDialogRef {
  open: ({
    onSubmit,
    onCancel,
  }: {
    onSubmit: (fields: Record<string, string>) => void;
    onCancel: () => void;
  }) => void;
}

export const FormDialog = forwardRef<FormDialogRef, FormDialogProps>(
  (props, ref) => {
    const {
      title = "Are you sure",
      children,
      buttonCancelColor = "dark.3",
      buttonCancelText = "Cancel",
      buttonSubmitColor = "brand",
      buttonSubmitText = "Submit",
    } = props;

    const _onSubmit = useRef<(fields: Record<string, string>) => void>(
      () => {},
    );
    const _onCancel = useRef(() => {});

    const [isOpen, setOpen] = useState(false);

    const handleSubmit = (event: React.FormEvent) => {
      event.preventDefault();
      const formData = new FormData(event.currentTarget as HTMLFormElement);
      const fields: Record<string, string> = {};
      formData.forEach((value, key) => {
        fields[key] = value as string;
      });
      setOpen(false);
      _onSubmit.current(fields);
    };

    useImperativeHandle(
      ref,
      () => ({
        open: ({ onSubmit, onCancel }) => {
          setOpen(true);
          _onSubmit.current = onSubmit;
          _onCancel.current = () => {
            setOpen(false);
            onCancel();
          };
        },
      }),
      [],
    );

    return (
      <>
        <Modal
          centered
          opened={isOpen}
          onClose={() => setOpen(false)}
          title={
            <Subtitle c="brand.8" fz="md" level={2}>
              {title}
            </Subtitle>
          }
        >
          <Flex
            component="form"
            direction="column"
            gap="lg"
            onSubmit={handleSubmit}
          >
            <Box flex={1}>{children}</Box>

            <Flex gap="sm" justify="flex-end">
              <Button
                color={buttonCancelColor}
                variant="outline"
                onClick={_onCancel.current}
              >
                {buttonCancelText}
              </Button>
              <Button color={buttonSubmitColor} type="submit">
                {buttonSubmitText}
              </Button>
            </Flex>
          </Flex>
        </Modal>
      </>
    );
  },
);
