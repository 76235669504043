import { z } from "zod";
import { MessageErrorSchema } from "../schemas/http-response.schema.js";
import {
  InviteMarketProfessionalSchema,
  ValidateInviteRequestSchema,
  ValidateInviteResponseSchema,
} from "../schemas/invite.schema.js";
import { StatusCodes } from "../utils/http-status-codes.js";
import { apiContract } from "./contract.js";

export const InviteOpenRouter = apiContract.router(
  {
    // ---- Validate Invite ----
    validateInvite: {
      method: "POST",
      path: "invite/validate",
      body: ValidateInviteRequestSchema,
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: ValidateInviteResponseSchema,
        }),
        [StatusCodes.BadRequest]: MessageErrorSchema,
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Validate Invite",
    },
  },

  // ---- Options ----
  {
    pathPrefix: "/api/invite/",
    strictStatusCodes: true,
  },
);

export const InviteRouter = apiContract.router(
  {
    // ---- Invite market professional ----
    inviteMarketProfessional: {
      method: "POST",
      path: "invite/market-professional",
      body: InviteMarketProfessionalSchema,
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
        }),
        [StatusCodes.Conflict]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Invite market professional",
    },
  },

  // ---- Options ----
  {
    pathPrefix: "/api/invite/",
    strictStatusCodes: true,
  },
);
