import { MarketProfOrg, User } from "@munivestor/models";
import {
  AuctionProfessionalTypeEnum,
  AuctionStatusEnum,
  USStates,
} from "@munivestor/types";
import { createSelectSchema } from "drizzle-zod";
import { z } from "zod";

// ---- Market Professional Onboarding Response ----
export const MarketProfOnboardingResponseSchema = createSelectSchema(
  MarketProfOrg,
)
  .pick({
    id: true,
    status: true,
  })
  .merge(
    createSelectSchema(User).pick({
      email: true,
      firstName: true,
      lastName: true,
      address: true,
      businessDesignation: true,
      city: true,
      phone: true,
      phoneExtension: true,
      state: true,
      zipCode: true,
    }),
  );

export type MarketProfOnboardingResponseType = z.infer<
  typeof MarketProfOnboardingResponseSchema
>;

// ---- Market Professional Onboarding Form ----
export const MarketProfOnboardingFormSchema = z.strictObject({
  firstName: z.string().min(1, { message: "First name is required" }),
  lastName: z.string().min(1, { message: "Last name is required" }),
  businessDesignation: z.string().optional(),
  address: z.string().min(1, { message: "Address is required" }),
  city: z.string().min(1, { message: "City is required" }),
  state: z
    .string()
    .min(1, { message: "State is required" })
    .refine((val) => USStates.find((state) => state.id === val), {
      message: "Invalid state",
    }),
  zipCode: z.string().min(1, { message: "Zip code is required" }),
  phone: z.string().optional(),
  phoneExtension: z.string().optional(),
});

export type MarketProfOnboardingFormType = z.infer<
  typeof MarketProfOnboardingFormSchema
>;

//  ---- Market Professional List ----
export const MarketProfessionalListSchema = createSelectSchema(MarketProfOrg)
  .pick({
    id: true,
    status: true,
    name: true,
  })
  .merge(
    z.object({
      user: createSelectSchema(User).pick({
        businessDesignation: true,
        city: true,
        state: true,
      }),
    }),
  );

export type MarketProfessionalListType = z.infer<
  typeof MarketProfessionalListSchema
>;

// ---- Market Professional Detail ----
export const MarketProfessionalDetailSchema = createSelectSchema(MarketProfOrg)
  .pick({
    id: true,
    status: true,
    name: true,
  })
  .merge(
    z.object({
      user: createSelectSchema(User).pick({
        businessDesignation: true,
        city: true,
        state: true,
        zipCode: true,
      }),
    }),
  );

export type MarketProfessionalDetailType = z.infer<
  typeof MarketProfessionalDetailSchema
>;

export const MarketProfessionalConnectionResponseSchema = z.object({
  name: z.string(),
  uuid: z.string().uuid(),
  status: z.enum([
    AuctionStatusEnum.Approved,
    AuctionStatusEnum.Live,
    AuctionStatusEnum.Draft,
    AuctionStatusEnum.Ended,
    AuctionStatusEnum.PendingReview,
    AuctionStatusEnum.Rejected,
    AuctionStatusEnum.Scheduled,
  ]),
  type: z.array(
    z.enum([
      AuctionProfessionalTypeEnum.AuctionTrustee,
      AuctionProfessionalTypeEnum.BondCounsel,
      AuctionProfessionalTypeEnum.MunicipalAdvisor,
    ]),
  ),
});

export type MarketProfessionalConnectionResponseType = z.infer<
  typeof MarketProfessionalConnectionResponseSchema
>;
