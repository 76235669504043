export const StatusCodes = {
  Ok: 200 as const,
  Created: 201 as const,
  Accepted: 202 as const,
  NonAuthoritativeInformation: 203 as const,
  NoContent: 204 as const,
  ResetContent: 205 as const,
  PartialContent: 206 as const,
  MultiStatus: 207 as const,

  Continue: 100 as const,
  SwitchingProtocols: 101 as const,
  Processing: 102 as const,
  MultipleChoices: 300 as const,
  MovedPermanently: 301 as const,
  Found: 302 as const,
  SeeOther: 303 as const,
  NotModified: 304 as const,
  UseProxy: 305 as const,
  TemporaryRedirect: 307 as const,
  PermanentRedirect: 308 as const,
  BadRequest: 400 as const,
  Unauthorized: 401 as const,
  PaymentRequired: 402 as const,
  Forbidden: 403 as const,
  NotFound: 404 as const,
  MethodNotAllowed: 405 as const,
  NotAcceptable: 406 as const,
  ProxyAuthenticationRequired: 407 as const,
  RequestTimeout: 408 as const,
  Conflict: 409 as const,
  Gone: 410 as const,
  LengthRequired: 411 as const,
  PreconditionFailed: 412 as const,
  PayloadTooLarge: 413 as const,
  UriTooLong: 414 as const,
  UnsupportedMediaType: 415 as const,
  RangeNotSatisfiable: 416 as const,
  ExpectationFailed: 417 as const,
  ImATeapot: 418 as const,
  MisdirectedRequest: 421 as const,
  UnprocessableEntity: 422 as const,
  Locked: 423 as const,
  FailedDependency: 424 as const,
  PreconditionRequired: 428 as const,
  TooManyRequests: 429 as const,
  RequestHeaderFieldsTooLarge: 431 as const,
  UnavailableForLegalReasons: 451 as const,
  InternalServerError: 500 as const,
  NotImplemented: 501 as const,
  BadGateway: 502 as const,
  ServiceUnavailable: 503 as const,
  GatewayTimeout: 504 as const,
  HttpVersionNotSupported: 505 as const,
  NetworkAuthenticationRequired: 511 as const,
} as const;
