import { z } from "zod";

// ---- Admin Signup Payload ----
export const SignupAdminRequestSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email(),
  idToken: z.string(),
});

export type SignupAdminRequestType = z.infer<typeof SignupAdminRequestSchema>;

// --- Admin Signup Form ----
export const SignupAdminFormSchema = SignupAdminRequestSchema.omit({
  idToken: true,
}).extend({
  password: z.string().min(8, "Password must be at least 8 characters"),
});

export type SignupAdminFormType = z.infer<typeof SignupAdminFormSchema>;

// ---- Admin User Metadata ----
export const AdminUserMetadataSchema = z.object({
  id: z.number(),
  email: z.string(),
  firstName: z.string(),
  lastName: z.string(),
});

export type AdminUserMetadataType = z.infer<typeof AdminUserMetadataSchema>;
