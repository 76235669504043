import { z } from "zod";

// ---- Auction Community Impact Themes ----
export const AuctionCommunityImpactThemesSchema = z.object({
  label: z.string(),
  value: z.string(),
});

export type AuctionCommunityImpactThemesType = z.infer<
  typeof AuctionCommunityImpactThemesSchema
>;

// ---- Auction Maturity Structure ----
export const AuctionMaturityStructureSchema = z.array(
  z.object({
    isOffered: z.boolean().default(true),
    maturityDate: z.coerce.date(),
    principalAmount: z.coerce
      .number()
      .refine((val) => val >= 0, {
        message: "Must be greater than or equal to 0",
      })
      .default(0),
    interestRate: z.coerce
      .number()
      .refine((val) => val >= 0, {
        message: "Must be greater than or equal to 0",
      })
      .default(0),
    yield: z.coerce
      .number()
      .refine((val) => val >= 0, {
        message: "Must be greater than or equal to 0",
      })
      .default(0),
    cusip: z.string(),
    price: z.coerce
      .number()
      .refine((val) => val >= 0, {
        message: "Must be greater than or equal to 0",
      })
      .default(0),
  }),
);

export type AuctionMaturityStructureType = z.infer<
  typeof AuctionMaturityStructureSchema
>;

// ---- Approval Log ----
export const ApprovalLogSchema = z.object({
  uuid: z.string(),
  userUuid: z.string(),
  action: z.enum(["REQUEST", "APPROVE", "REJECT"]),
  remarks: z.string(),
  timestamp: z.coerce.date(),
});

export type ApprovalLogType = z.infer<typeof ApprovalLogSchema>;
