import type { AxiosHeaders } from "axios";
import axios from "axios";
import { firebaseAuthService } from "./firebase.js";

// Instance with idToken
export const axiosAuthedInstance = axios.create({
  baseURL: "", // set in the contract
  headers: { "Content-Type": "application/json" },
});

axiosAuthedInstance.interceptors.request.use(
  async (config) => {
    const token = await firebaseAuthService.getIdToken();

    if (!token) {
      throw new Error("Not signed in");
    }

    if (config.headers) {
      (config.headers as AxiosHeaders).set("Authorization", `Bearer ${token}`);
    }
    return config;
  },
  (error) => Promise.reject(error),
);
