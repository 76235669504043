import { text } from "drizzle-orm/pg-core";
import { createCommonCols } from "../helpers/common-cols.js";
import { adminSchema } from "../helpers/pg-schema.js";

export const AdminUser = adminSchema.table("admin_user", {
  ...createCommonCols("admin_user"),
  firstName: text("firstName").notNull(),
  lastName: text("lastName").notNull(),
  email: text("email").notNull().unique(),
  uid: text("uid").notNull().unique(),
});
