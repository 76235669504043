export enum QueryKeys {
  // Auth
  Auth = "Auth",
  FirebaseConfig = "FirebaseConfig",
  UserMetadata = "UserMetadata",

  // User Management
  Municipalities = "Municipalities",
  MunicipalityInfo = "MunicipalityInfo",
  IndividualInvestors = "IndividualInvestors",
  IndividualInvestorInfo = "IndividualInvestorInfo",
  InstitutionalInvestors = "InstitutionalInvestors",
  InstitutionalInvestorInfo = "InstitutionalInvestorInfo",
  MarketProfessionalList = "MarketProfessionalList",
  MarketProfessionalInfo = "MarketProfessionalInfo",

  // Auctions
  AuctionList = "AuctionList",
  AuctionDetails = "AuctionDetails",
}
