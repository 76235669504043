import { z } from "zod";
import {
  AboutInstitutionFormSchema,
  AboutInvestorFormSchema,
  InstitutionalInvestorOnboardingResponseSchema,
  MessageErrorSchema,
} from "../schemas/index.js";
import { StatusCodes } from "../utils/http-status-codes.js";
import { apiContract } from "./contract.js";

export const OnboardingInstitutionalInvestorRouter = apiContract.router(
  {
    // ---- Get investor profile ----
    getInvestor: {
      method: "GET",
      path: "",
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: InstitutionalInvestorOnboardingResponseSchema,
        }),
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Fetch investor onboarding info",
    },

    // ---- Update investor overview ----
    updateInvestor: {
      method: "PUT",
      path: "",
      body: AboutInvestorFormSchema,
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
        }),
        [StatusCodes.BadRequest]: MessageErrorSchema,
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Update institutional investor overview",
    },

    // ---- Update institution overview ----
    updateInstitution: {
      method: "PUT",
      path: "institution",
      body: AboutInstitutionFormSchema,
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
        }),
        [StatusCodes.BadRequest]: MessageErrorSchema,
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
        [StatusCodes.Unauthorized]: MessageErrorSchema,
      },
      summary: "Update institutional investor overview",
    },

    // ---- Request onboarding approval  ----
    requestInstitutionalInvestorApproval: {
      method: "POST",
      path: "request-approval",
      body: z.unknown(),
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
        }),
        [StatusCodes.BadRequest]: MessageErrorSchema,
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
        [StatusCodes.Unauthorized]: MessageErrorSchema,
      },
      summary: "Request approval for institutional investor onboarding",
    },
  },

  // ---- Options ----
  {
    pathPrefix: "/api/onboarding/institutional-investor/",
    strictStatusCodes: true,
  },
);
