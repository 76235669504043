import type {
  ApprovalLogType,
  AuctionCommunityImpactThemesType,
  AuctionMaturityStructureType,
} from "@munivestor/types";
import { AuctionStatusEnum } from "@munivestor/types";
import {
  pgTable,
  text,
  date,
  decimal,
  integer,
  jsonb,
  index,
  timestamp,
} from "drizzle-orm/pg-core";
import { createCommonCols } from "../helpers/common-cols.js";
import {
  auctionOfferingTypesPgEnum,
  auctionRedemptionProvisionsPgEnum,
  auctionRepaymentFreqPgEnum,
  auctionSecurityTypesPgEnum,
  auctionStatusPgEnum,
  auctionTaxStatusTypesPgEnum,
  creditRatingValuesFitchPgEnum,
  creditRatingValuesKrollPgEnum,
  creditRatingValuesMoodysPgEnum,
  creditRatingValuesSnPPgEnum,
} from "../helpers/enums.js";
import { Municipality } from "./municipality.entity.js";
import { UploadedFile } from "./uploaded-file.entity.js";

export const Auction = pgTable(
  "auction",
  {
    ...createCommonCols("auction"),
    municipalityId: integer("municipalityId")
      .notNull()
      .references(() => Municipality.id),
    name: text("name").notNull(),
    baseCusip: text("baseCusip").notNull(),
    taxStatus: auctionTaxStatusTypesPgEnum("taxStatus").notNull(),
    redemptionProvisions: auctionRedemptionProvisionsPgEnum(
      "redemptionProvisions",
    ).notNull(),
    redemptionDate: date("redemptionDate"),
    redemptionPrice: decimal("redemptionPrice", {
      precision: 15,
      scale: 2,
    }),
    amount: decimal("amount", {
      precision: 15,
      scale: 2,
    }).notNull(),
    offeringType: auctionOfferingTypesPgEnum("offeringType").notNull(),
    securityType: auctionSecurityTypesPgEnum("securityType").notNull(),
    repaymentFrequency:
      auctionRepaymentFreqPgEnum("repaymentFrequency").notNull(),
    purpose: text("purpose"),
    useOfFunds: text("useOfFunds"),
    repaymentSource: text("repaymentSource"),

    fitchRating: creditRatingValuesFitchPgEnum("fitchRating"),
    moodysRating: creditRatingValuesMoodysPgEnum("moodysRating"),
    snpRating: creditRatingValuesSnPPgEnum("snpRating"),
    krollRating: creditRatingValuesKrollPgEnum("krollRating"),

    priorityStateInst: integer("priorityStateInst").notNull().default(1),
    priorityNationalInst: integer("priorityNationalInst").notNull().default(2),
    priorityStateInd: integer("priorityStateInd").notNull().default(3),
    priorityNationalInd: integer("priorityNationalInd").notNull().default(4),
    priorityInternationalInst: integer("priorityInternationalInst")
      .notNull()
      .default(5),
    priorityInternationalInd: integer("priorityInternationalInd")
      .notNull()
      .default(6),
    marketingPeriodLaunchDate: timestamp("marketingPeriodLaunch", {
      mode: "string",
      withTimezone: true,
    }),
    investorPresentationDate: timestamp("investorPresentationDate", {
      mode: "date",
      withTimezone: true,
    }),
    investorPresentationUrl: text("investorPresentationUrl"),
    dated: date("dated"),
    firstPrincipalPaymentDate: date("firstPrincipalPaymentDate"),
    finalMaturityDate: date("finalMaturityDate"),

    communityImpactThemes: jsonb("communityImpactThemes")
      .default([])
      .$type<Array<AuctionCommunityImpactThemesType>>(),

    docInvestorPresentationId: integer("docInvestorPresentationId").references(
      () => UploadedFile.id,
      {
        onDelete: "set null",
      },
    ),
    docPreliminaryOfficialStatementId: integer(
      "docPreliminaryOfficialStatementId",
    ).references(() => UploadedFile.id, {
      onDelete: "set null",
    }),
    docOther1Id: integer("docOther1Id").references(() => UploadedFile.id, {
      onDelete: "set null",
    }),
    docOther2Id: integer("docOther2Id").references(() => UploadedFile.id, {
      onDelete: "set null",
    }),

    maturityStructure: jsonb("maturityStructure")
      .default([])
      .$type<AuctionMaturityStructureType>(),

    status: auctionStatusPgEnum("status")
      .notNull()
      .default(AuctionStatusEnum.Draft),
    startDate: timestamp("startDate", {
      mode: "date",
      withTimezone: true,
    }),
    closeDate: timestamp("closeDate", {
      mode: "date",
      withTimezone: true,
    }),
    approvalHistory: jsonb("approvalHistory")
      .notNull()
      .default([])
      .$type<Array<ApprovalLogType>>(),
  },
  (table) => ({
    uuidIndex: index("auction_uuid_idx").on(table.uuid),
  }),
);

export type AuctionEntityType = typeof Auction.$inferSelect;
