import { z } from "zod";
import { MessageErrorSchema } from "../schemas/http-response.schema.js";
import {
  MunicipalityDetailsForPublicServiceSchema,
  MunicipalityListViewForPublicServiceSchema,
} from "../schemas/public/municipality.schema.js";
import { StatusCodes } from "../utils/http-status-codes.js";
import { apiContract } from "./contract.js";

export const PublicApiRouter = apiContract.router(
  {
    municipality: {
      list: {
        method: "GET",
        path: "municipalities",
        responses: {
          [StatusCodes.Ok]: z.object({
            message: z.string(),
            data: z.array(MunicipalityListViewForPublicServiceSchema),
          }),
          [StatusCodes.BadRequest]: MessageErrorSchema,
          [StatusCodes.Forbidden]: MessageErrorSchema,
          [StatusCodes.InternalServerError]: MessageErrorSchema,
        },
        summary: "List of municipalities",
      },

      details: {
        method: "GET",
        path: "municipalities/:uuid",
        pathParams: z.object({
          uuid: z.string(),
        }),
        responses: {
          [StatusCodes.Ok]: z.object({
            message: z.string(),
            data: MunicipalityDetailsForPublicServiceSchema,
          }),
          [StatusCodes.NotFound]: MessageErrorSchema,
          [StatusCodes.Forbidden]: MessageErrorSchema,
          [StatusCodes.InternalServerError]: MessageErrorSchema,
        },
        summary: "Details of municipality",
      },
    },
  },

  // ---- Options ----
  {
    pathPrefix: "/",
    strictStatusCodes: true,
  },
);
