import type { BadgeProps } from "@mantine/core";
import { Badge } from "@mantine/core";
import { AuctionStatusEnum } from "@munivestor/types";
import { z } from "zod";

interface Props extends BadgeProps {
  status?: AuctionStatusEnum | string;
}

export function AuctionStatusBadge(props: Props) {
  const { status, ...rest } = props;
  if (!status) {
    return null;
  }
  const parsedStatus = z.nativeEnum(AuctionStatusEnum).safeParse(status);
  if (!parsedStatus.success) {
    return null;
  }
  const { color, label } = BadgeMap[parsedStatus.data];
  return (
    <Badge color={color} variant="outline" {...rest}>
      {label}
    </Badge>
  );
}

const BadgeMap: Record<AuctionStatusEnum, { color: string; label: string }> = {
  [AuctionStatusEnum.Live]: {
    color: "green",
    label: "Active",
  },
  [AuctionStatusEnum.Approved]: {
    color: "blue",
    label: "Approved",
  },
  [AuctionStatusEnum.Scheduled]: {
    color: "orange",
    label: "Scheduled",
  },
  [AuctionStatusEnum.Rejected]: {
    color: "red",
    label: "Rejected",
  },
  [AuctionStatusEnum.Draft]: {
    color: "gray",
    label: "Draft",
  },
  [AuctionStatusEnum.PendingReview]: {
    color: "yellow",
    label: "Pending",
  },
  [AuctionStatusEnum.Ended]: {
    color: "gray.7",
    label: "Ended",
  },
};
