import { LevelOfInterestEnum } from "@munivestor/types";
import { integer, pgTable, text } from "drizzle-orm/pg-core";
import { createCommonCols } from "../helpers/common-cols.js";
import { levelOfInterestPgEnum } from "../helpers/enums.js";
import { Auction } from "./auction.entity.js";
import { User } from "./user.entity.js";

export const AuctionFeedback = pgTable("auction_feedback", {
  ...createCommonCols("auction_feedback"),
  auctionId: integer("auctionId")
    .notNull()
    .references(() => Auction.id),
  feedback: text("feedback"),
  levelOfInterest: levelOfInterestPgEnum("status")
    .notNull()
    .default(LevelOfInterestEnum.NoInterest),
  userId: integer("userId")
    .notNull()
    .references(() => User.id),
});

export type AuctionFeedbackEntityType = typeof AuctionFeedback.$inferSelect;
