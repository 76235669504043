import { AppPublicRouter } from "@munivestor/contracts";
import { initQueryClient } from "@ts-rest/react-query";
import { customApiFactory } from "./apiFactory.js";
import { axiosPublicInstance } from "./axiosUnauthedInstances.js";

export const ApiPublicClient = initQueryClient(AppPublicRouter, {
  baseUrl: "",
  baseHeaders: {},
  api: customApiFactory(axiosPublicInstance),
});
