import { integer, pgTable } from "drizzle-orm/pg-core";
import { createCommonCols } from "../helpers/common-cols.js";
import { userRolesPgEnum } from "../helpers/enums.js";
import { InvestorOrg } from "./investor-org.entity.js";
import { MarketProfOrg } from "./market-prof-org.entity.js";
import { Municipality } from "./municipality.entity.js";
import { User } from "./user.entity.js";

export const UserOrg = pgTable("user_org", {
  ...createCommonCols("user_org"),
  userId: integer("userId")
    .notNull()
    .references(() => User.id),
  municipalityId: integer("municipalityId").references(() => Municipality.id),
  investorOrgId: integer("investorOrgId").references(() => InvestorOrg.id),
  marketProfOrgId: integer("marketProfOrgId").references(
    () => MarketProfOrg.id,
  ),
  role: userRolesPgEnum("role").notNull(),
});

export type UserOrgEntityType = typeof UserOrg.$inferSelect;
