import { z } from "zod";
import { RejectMunicipalitySchema } from "../../schemas/admin/municipality.schema.js";
import { MunicipalityListItemSchema } from "../../schemas/admin/user-management.js";
import { MessageErrorSchema } from "../../schemas/http-response.schema.js";
import {
  InstInvestorDetailSchema,
  InstInvestorListSchema,
} from "../../schemas/institution.schema.js";
import {
  IndieInvestorDetailSchema,
  IndieInvestorListSchema,
} from "../../schemas/investor.schema.js";
import {
  MarketProfessionalDetailSchema,
  MarketProfessionalListSchema,
} from "../../schemas/market-professional.schema.js";
import { MunicipalityOnboardingInfoResponseSchema } from "../../schemas/municipality.schema.js";
import { StatusCodes } from "../../utils/http-status-codes.js";
import { apiContract } from "../contract.js";

export const AdminUserManagementRouter = apiContract.router(
  {
    // ---- Get municipality list ----
    getMunicipalities: {
      method: "GET",
      path: "municipalities",
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: MunicipalityListItemSchema.array(),
        }),
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Fetch list of municipalities",
    },

    // ---- Get municipality info ----
    getMunicipalityInfo: {
      method: "GET",
      path: "municipality/:id",
      pathParams: z.object({
        id: z.coerce.number(),
      }),
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: MunicipalityOnboardingInfoResponseSchema,
        }),
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Fetch onboarding info of a municipality",
    },

    // ---- Approve municipality ----
    approveMunicipality: {
      method: "POST",
      path: "municipality/:id/approve",
      pathParams: z.object({
        id: z.coerce.number(),
      }),
      body: z.unknown(),
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: MunicipalityListItemSchema,
        }),
        [StatusCodes.BadRequest]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Approve municipality",
    },

    // ---- Reject municipality ----
    rejectMunicipality: {
      method: "POST",
      path: "municipality/:id/reject",
      pathParams: z.object({
        id: z.coerce.number(),
      }),
      body: RejectMunicipalitySchema,
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: MunicipalityListItemSchema,
        }),
        [StatusCodes.BadRequest]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Reject municipality",
    },

    // ---- Get investor list ----
    getInvestors: {
      method: "GET",
      path: "investors",
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: IndieInvestorListSchema.array(),
        }),
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Fetch list of Individual Investors",
    },

    // ---- Get investor info ----
    getInvestorInfo: {
      method: "GET",
      path: "investor/:id",
      pathParams: z.object({
        id: z.coerce.number(),
      }),
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: IndieInvestorDetailSchema,
        }),
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Fetch details of an investor",
    },

    // ---- Get inst investor list ----
    getInstInvestors: {
      method: "GET",
      path: "institutional-investors",
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: InstInvestorListSchema.array(),
        }),
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Fetch list of Institutional Investors",
    },

    // ---- Get investor info ----
    getInstInvestorInfo: {
      method: "GET",
      path: "institutional-investor/:id",
      pathParams: z.object({
        id: z.coerce.number(),
      }),
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: InstInvestorDetailSchema,
        }),
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Fetch details of an investor",
    },

    // ---- Get market professional list ----
    getMarketProfessionals: {
      method: "GET",
      path: "market-professionals",
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: MarketProfessionalListSchema.array(),
        }),
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Fetch list of Market Professionals",
    },

    // ---- Get market professional info ----
    getMarketProfessionalInfo: {
      method: "GET",
      path: "market-professional/:id",
      pathParams: z.object({
        id: z.coerce.number(),
      }),
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: MarketProfessionalDetailSchema,
        }),
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Fetch details of a Market Professional",
    },
  },
  // ---- Options ----
  {
    pathPrefix: "/api/user-management/",
    strictStatusCodes: true,
  },
);
