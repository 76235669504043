import { pgTable, text, integer, timestamp } from "drizzle-orm/pg-core";
import { createCommonCols } from "../helpers/common-cols.js";
import { userTypesPgEnum } from "../index.js";
import { User } from "./user.entity.js";

export const UserInvite = pgTable("user_invite", {
  ...createCommonCols("user_invite"),
  code: text("code").notNull().unique(),
  email: text("email").notNull().unique(),
  name: text("name"),
  userType: userTypesPgEnum("userType"),
  invitedById: integer("invitedById")
    .notNull()
    .references(() => User.id),
  acceptedAt: timestamp("acceptedAt", { withTimezone: true }),
});

export type UserInviteEntityType = typeof UserInvite.$inferSelect;
