import { Municipality } from "@munivestor/models";
import { createSelectSchema } from "drizzle-zod";
import { z } from "zod";

// ---- Municipality Listview ----
export const MunicipalityListViewForPublicServiceSchema = createSelectSchema(
  Municipality,
).pick({
  uuid: true,
  name: true,
  city: true,
  state: true,
  type: true,
  summary: true,
  website: true,
  emmaIssuerUrl: true,
  sifmaUrl: true,
  zipCode: true,
  censusDataUrl: true,
});

export type MunicipalityListViewForPublicServiceType = z.infer<
  typeof MunicipalityListViewForPublicServiceSchema
>;

// ---- Municipality Details ----
export const MunicipalityDetailsForPublicServiceSchema = createSelectSchema(
  Municipality,
)
  .pick({
    uuid: true,
    name: true,
    city: true,
    state: true,
    type: true,
    summary: true,
    website: true,
    emmaIssuerUrl: true,
    sifmaUrl: true,
    zipCode: true,
    censusDataUrl: true,
  })
  .extend({
    numActiveAuctions: z.number(),
  });

export type MunicipalityDetailsForPublicServiceType = z.infer<
  typeof MunicipalityDetailsForPublicServiceSchema
>;
