import { UserTypeEnum } from "@munivestor/types";
import { z } from "zod";

// ---- Accept Invite ----
export const AcceptInviteFormSchema = z.object({
  email: z.string().email(),
  inviteCode: z.string(),
});

export type AcceptInviteFormType = z.infer<typeof AcceptInviteFormSchema>;

export const ValidateInviteRequestSchema = AcceptInviteFormSchema.extend({
  recaptchaToken: z.string(),
});

export type ValidateInviteRequestType = z.infer<
  typeof ValidateInviteRequestSchema
>;

export const ValidateInviteResponseSchema = z.object({
  userType: z.nativeEnum(UserTypeEnum).optional(),
});

export type ValidateInviteResponseType = z.infer<
  typeof ValidateInviteResponseSchema
>;

// ---- Invite market professional ----
export const InviteMarketProfessionalSchema = z.object({
  email: z.string().email(),
  name: z.string(),
});

export type InviteMarketProfessionalType = z.infer<
  typeof InviteMarketProfessionalSchema
>;
