import { z } from "zod";

export const MessageErrorSchema = z.object({
  message: z.string(),
  data: z.unknown().optional(),
});

export type MessageErrorType = z.infer<typeof MessageErrorSchema>;

export const ResponseMessageSchema = z.object({
  message: z.string(),
});

export type ResponseMessageType = z.infer<typeof ResponseMessageSchema>;
