import { boolean, integer, pgTable, text } from "drizzle-orm/pg-core";
import { createCommonCols } from "../helpers/common-cols.js";
import { Municipality } from "./municipality.entity.js";

export const MuniPersonnel = pgTable("muni_personnel", {
  ...createCommonCols("muni_personnel"),
  name: text("name").notNull(),
  title: text("title").notNull(),
  bio: text("bio").notNull(),
  phone: text("phone").notNull(),
  phoneExtension: text("phoneExtension"),
  email: text("email").notNull(),
  linkedInUrl: text("linkedInUrl"),
  rank: integer("rank").notNull().default(1),
  municipalityId: integer("municipalityId")
    .notNull()
    .references(() => Municipality.id),
  showOnProfile: boolean("showOnProfile").notNull().default(true),
});

export type MuniPersonnelEntityType = typeof MuniPersonnel.$inferSelect;
