import { AccountStatusEnum } from "@munivestor/types";
import { boolean, jsonb, pgTable, text } from "drizzle-orm/pg-core";
import { createCommonCols } from "../helpers/common-cols.js";
import { accountStatusPgEnum } from "../helpers/enums.js";

export const InvestorOrg = pgTable("investor_org", {
  ...createCommonCols("investor_org"),
  name: text("name").notNull(),
  summary: text("summary"),
  website: text("website"),
  isInstitution: boolean("isInstitution").notNull().default(false),
  status: accountStatusPgEnum("status")
    .notNull()
    .default(AccountStatusEnum.New),
  city: text("city"),
  state: text("state"),
  investmentExperience: text("investmentExperience"),
  targetIssuers: jsonb("targetIssuers").default([]).$type<Array<string>>(),
  isInterestedInChat: boolean("isInterestedInChat").notNull().default(false),
  isSeekingFeaturedInvestorStatus: boolean("isSeekingFeaturedInvestorStatus")
    .notNull()
    .default(false),
});

export type InvestorOrgEntityType = typeof InvestorOrg.$inferSelect;
