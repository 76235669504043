import { include } from "named-urls";

export const RouteMap = {
  Public: include("/", {
    home: "",
    signin: "signin",
    signup: "signup",
    signinHelp: "signin-help",
  }),
  Dashboard: include("/dashboard", {
    index: "",
  }),
  IndividualInvestors: include("/individual-investors", {
    index: "",
    details: ":id",
  }),
  InstitutionalInvestors: include("/institutional-investors", {
    index: "",
    details: ":id",
  }),
  MarketProfessionals: include("/market-professionals", {
    index: "",
    details: ":id",
  }),
  ManageAuctions: include("/auctions", {
    index: "",
    details: ":id",
  }),
  MunicipalityDetails: "/municipality/:id",
  UnderConstruction: include("/under-construction", {
    index: "",
  }),
};
