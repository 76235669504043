import { z } from "zod";
import { FavoritesResponseSchema } from "../index.js";
import { MessageErrorSchema } from "../schemas/http-response.schema.js";
import { StatusCodes } from "../utils/http-status-codes.js";
import { apiContract } from "./contract.js";

export const SocialRouter = apiContract.router(
  {
    getFavoriteMunicipalities: {
      method: "GET",
      path: "fav-muni",
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: z.array(z.string().uuid()),
        }),
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "List of Favorite municipality uuids",
    },

    favoriteMunicipality: {
      method: "POST",
      path: "fav-muni/:id",
      body: z.object({
        isFavorite: z.boolean(),
      }),
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: z.array(z.string().uuid()),
        }),
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Favorite/Unfavorite a municipality",
    },

    getFavoriteAuctions: {
      method: "GET",
      path: "fav-auction",
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: z.array(z.string().uuid()),
        }),
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "List of Favorite auction uuids",
    },

    favoriteAuction: {
      method: "POST",
      path: "fav-auction/:id",
      body: z.object({
        isFavorite: z.boolean(),
      }),
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: z.array(z.string().uuid()),
        }),
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Favorite/Unfavorite an auction",
    },

    shareableHostUrl: {
      method: "GET",
      path: "shareable-host-url",
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: z.string(),
        }),
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Get the host URL of the public service",
    },

    getFavorites: {
      method: "GET",
      path: "favorites",
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: FavoritesResponseSchema,
        }),
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.Forbidden]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "List of favorites",
    },
  },

  // ---- Options ----
  {
    pathPrefix: "/api/social/",
    strictStatusCodes: true,
  },
);
