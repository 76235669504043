import { InvestorOrg, User } from "@munivestor/models";
import { USStates } from "@munivestor/types";
import { createSelectSchema } from "drizzle-zod";
import { z } from "zod";

// ---- Indie Investor Overview ----
export const IndieInvestorOnboardingResponseSchema = createSelectSchema(
  InvestorOrg,
)
  .pick({
    id: true,
    status: true,
  })
  .merge(
    createSelectSchema(User).pick({
      email: true,
      firstName: true,
      lastName: true,
      address: true,
      businessDesignation: true,
      city: true,
      phone: true,
      phoneExtension: true,
      state: true,
      zipCode: true,
    }),
  );

export type IndieInvestorOnboardingResponseType = z.infer<
  typeof IndieInvestorOnboardingResponseSchema
>;

// ---- Overview ----
export const IndieInvestorOnboardingFormSchema = z.strictObject({
  firstName: z.string().min(1, { message: "First name is required" }),
  lastName: z.string().min(1, { message: "Last name is required" }),
  businessDesignation: z.string().optional(),
  address: z.string().min(1, { message: "Address is required" }),
  city: z.string().min(1, { message: "City is required" }),
  state: z
    .string()
    .min(1, { message: "State is required" })
    .refine((val) => USStates.find((state) => state.id === val), {
      message: "Invalid state",
    }),
  zipCode: z.string().min(1, { message: "Zip code is required" }),
  phone: z.string().optional(),
  phoneExtension: z.string().optional(),
});

export type IndieInvestorOnboardingFormType = z.infer<
  typeof IndieInvestorOnboardingFormSchema
>;

//  ---- Indie Investor List ----
export const IndieInvestorListSchema = createSelectSchema(InvestorOrg)
  .pick({
    id: true,
    status: true,
    name: true,
  })
  .merge(
    z.object({
      user: createSelectSchema(User).pick({
        businessDesignation: true,
        city: true,
        state: true,
      }),
    }),
  );

export type IndieInvestorListType = z.infer<typeof IndieInvestorListSchema>;

// ---- Indie Investor Detail ----

export const IndieInvestorDetailSchema = createSelectSchema(InvestorOrg)
  .pick({
    id: true,
    status: true,
    name: true,
  })
  .merge(
    z.object({
      user: createSelectSchema(User).pick({
        businessDesignation: true,
        city: true,
        state: true,
        zipCode: true,
      }),
    }),
  );

export type IndieInvestorDetailType = z.infer<typeof IndieInvestorDetailSchema>;

export const FavoriteEntitySchema = z.object({
  name: z.string(),
  uuid: z.string().uuid(),
});

export type FavoriteEntityType = z.infer<typeof FavoriteEntitySchema>;

export const FavoritesResponseSchema = z.object({
  auctions: z.array(FavoriteEntitySchema),
  municipalities: z.array(FavoriteEntitySchema),
});

export type FavoritesResponseType = z.infer<typeof FavoritesResponseSchema>;
