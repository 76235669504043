import { integer, pgTable, text } from "drizzle-orm/pg-core";
import { createCommonCols } from "../helpers/common-cols.js";
import { User } from "./user.entity.js";

export const UploadedFile = pgTable("uploaded_file", {
  ...createCommonCols("uploaded_file"),
  uploadedById: integer("uploadedById").references(() => User.id),
  title: text("title").notNull(),
  originalName: text("originalName").notNull(),
  destFileName: text("destFileName").notNull().unique(),
  caption: text("caption"),
  url: text("url").notNull(),
});

export type UploadedFileEntityType = typeof UploadedFile.$inferSelect;
