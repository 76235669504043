import { pgTable, integer } from "drizzle-orm/pg-core";
import { createCommonCols } from "../helpers/common-cols.js";
import { muniAttachmentTypesPgEnum } from "../helpers/enums.js";
import { Municipality } from "./municipality.entity.js";
import { UploadedFile } from "./uploaded-file.entity.js";

export const MunicipalityAttachment = pgTable("municipality_attachment", {
  ...createCommonCols("municipality_attachment"),
  municipalityId: integer("municipalityId")
    .notNull()
    .references(() => Municipality.id),
  type: muniAttachmentTypesPgEnum("type").notNull(),
  uploadedFileId: integer("uploadedFileId")
    .notNull()
    .unique()
    .references(() => UploadedFile.id),
});

export type MunicipalityAttachmentEntityType =
  typeof MunicipalityAttachment.$inferSelect;
