import { AuctionStatusEnum } from "@munivestor/types";
import { z } from "zod";

export const ApproveRejectAuctionSchema = z.object({
  status: z.nativeEnum(AuctionStatusEnum),
  remarks: z.string().nullish(),
});

export type ApproveRejectAuctionType = z.infer<
  typeof ApproveRejectAuctionSchema
>;

export const UpdateMarketingLaunchDateSchema = z.object({
  marketingPeriodLaunchDate: z.string(),
});

export type UpdateMarketingLaunchDateType = z.infer<
  typeof UpdateMarketingLaunchDateSchema
>;
