import { LoadingOverlay } from "@mantine/core";

export function LoaderFullscreen() {
  return (
    <LoadingOverlay
      visible
      overlayProps={{ blur: 2 }}
      zIndex={1000}
      pos="fixed"
    />
  );
}
