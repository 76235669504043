import { z } from "zod";
import {
  ApproveRejectAuctionSchema,
  UpdateMarketingLaunchDateSchema,
} from "../../schemas/admin/auction.schema.js";
import {
  AuctionDetailsSchema,
  AuctionListItemSchema,
  AuctionProfessionalSchema,
} from "../../schemas/auction.schema.js";
import { MessageErrorSchema } from "../../schemas/http-response.schema.js";
import { StatusCodes } from "../../utils/http-status-codes.js";
import { apiContract } from "../contract.js";

export const AdminAuctionRouter = apiContract.router(
  {
    listAuctions: {
      method: "GET",
      path: "",
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: z.array(AuctionListItemSchema),
        }),
        [StatusCodes.BadRequest]: MessageErrorSchema,
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.Forbidden]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "List of auctions entities",
    },

    // ---- Get auction details ----
    getAuctionDetails: {
      method: "GET",
      path: ":auctionId/details",
      pathParams: z.object({
        auctionId: z.string(),
      }),
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: AuctionDetailsSchema,
        }),
        [StatusCodes.BadRequest]: MessageErrorSchema,
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.Forbidden]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Get auction details for create/update",
    },

    // ---- List of market professionals ----
    listProfessionals: {
      method: "GET",
      path: "professionals",
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: z.array(AuctionProfessionalSchema),
        }),
        [StatusCodes.BadRequest]: MessageErrorSchema,
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "List of market professionals",
    },

    // ---- Approve or Reject auction ----
    approveRejectAuction: {
      method: "PATCH",
      path: ":auctionId/approve-reject",
      pathParams: z.object({
        auctionId: z.string(),
      }),
      body: ApproveRejectAuctionSchema,
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
          data: ApproveRejectAuctionSchema,
        }),
        [StatusCodes.BadRequest]: MessageErrorSchema,
        [StatusCodes.PreconditionFailed]: MessageErrorSchema,
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Approve or reject auction",
    },

    // ---- Update marketing period launch date of auction ----
    updateMarketingLaunchDate: {
      method: "PATCH",
      path: ":auctionId/marketing-launch-date",
      pathParams: z.object({
        auctionId: z.string(),
      }),
      body: UpdateMarketingLaunchDateSchema,
      responses: {
        [StatusCodes.Ok]: z.object({
          message: z.string(),
        }),
        [StatusCodes.BadRequest]: MessageErrorSchema,
        [StatusCodes.PreconditionFailed]: MessageErrorSchema,
        [StatusCodes.NotFound]: MessageErrorSchema,
        [StatusCodes.InternalServerError]: MessageErrorSchema,
      },
      summary: "Update marketing period launch date of auction",
    },
  },

  // ---- Options ----
  {
    pathPrefix: "/api/auction/",
    strictStatusCodes: true,
  },
);
